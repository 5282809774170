import React from 'react';
import PropTypes from 'prop-types';

import SocialNetworksShare from "./SocialNetworksShare";

const CampaignFeedback = ({title, description, facebook, twitter, whatsapp, telegram}) => {
    return (
        <>
            <p><strong>{ title }</strong> Agora ajude a espalhar compartilhando nas suas redes sociais.</p>

            <SocialNetworksShare twitter={twitter ? twitter : description}
                                 facebook={facebook ? facebook : description}
                                 whatsapp={whatsapp ? whatsapp : description}
                                 telegram={telegram ? telegram : description} />
        </>
    );
}

CampaignFeedback.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    telegram: PropTypes.string,
    whatsapp: PropTypes.string,
};

CampaignFeedback.element = CampaignFeedback;

export default CampaignFeedback;
