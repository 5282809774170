export const bairrosSP = [
    { value: "-", label: "Não moro em São Paulo" },
    { value: "Aclimação", label: "Aclimação" },
    { value: "Água Branca", label: "Água Branca" },
    { value: "Água Fria", label: "Água Fria" },
    { value: "Água Funda", label: "Água Funda" },
    { value: "Água Rasa", label: "Água Rasa" },
    { value: "Alto da Boa Vista", label: "Alto da Boa Vista" },
    { value: "Alto da Lapa", label: "Alto da Lapa" },
    { value: "Alto da Mooca", label: "Alto da Mooca" },
    { value: "Alto da Riviera", label: "Alto da Riviera" },
    { value: "Alto de Pinheiros", label: "Alto de Pinheiros" },
    { value: "Alto do Pari", label: "Alto do Pari" },
    { value: "Altos de Vila Prudente", label: "Altos de Vila Prudente" },
    { value: "Americanópolis", label: "Americanópolis" },
    { value: "Anhanguera", label: "Anhanguera" },
    { value: "Artur Alvim", label: "Artur Alvim" },
    { value: "Associação Sobradinho", label: "Associação Sobradinho" },
    { value: "Balneário Dom Carlos", label: "Balneário Dom Carlos" },
    { value: "Balneário Mar Paulista", label: "Balneário Mar Paulista" },
    { value: "Balneário São Francisco", label: "Balneário São Francisco" },
    { value: "Balneário São José", label: "Balneário São José" },
    { value: "Barra Funda", label: "Barra Funda" },
    { value: "Barragem", label: "Barragem" },
    { value: "Barro Branco", label: "Barro Branco" },
    { value: "Bela Aliança", label: "Bela Aliança" },
    { value: "Bela Vista", label: "Bela Vista" },
    { value: "Belenzinho", label: "Belenzinho" },
    { value: "Boacava", label: "Boacava" },
    { value: "Bom Retiro", label: "Bom Retiro" },
    { value: "Borore", label: "Borore" },
    { value: "Bortolândia", label: "Bortolândia" },
    { value: "Bosque da Saúde", label: "Bosque da Saúde" },
    { value: "Bras", label: "Bras" },
    { value: "Brasilândia", label: "Brasilândia" },
    { value: "Brooklin Novo", label: "Brooklin Novo" },
    { value: "Brooklin Paulista", label: "Brooklin Paulista" },
    { value: "Burgo Paulista", label: "Burgo Paulista" },
    { value: "Butantã", label: "Butantã" },
    { value: "Cambuci", label: "Cambuci" },
    { value: "Campininha", label: "Campininha" },
    { value: "Campo Belo", label: "Campo Belo" },
    { value: "Campo Grande", label: "Campo Grande" },
    { value: "Campo Limpo", label: "Campo Limpo" },
    { value: "Campos Elíseos", label: "Campos Elíseos" },
    { value: "Cangaiba", label: "Cangaiba" },
    { value: "Canindé", label: "Canindé" },
    { value: "Cantinho Ceu", label: "Cantinho Ceu" },
    { value: "Cantinho do Ceu", label: "Cantinho do Ceu" },
    { value: "Capão do Embira", label: "Capão do Embira" },
    { value: "Capão Redondo", label: "Capão Redondo" },
    { value: "Capela do Socorro", label: "Capela do Socorro" },
    { value: "Carandiru", label: "Carandiru" },
    { value: "Casa Verde", label: "Casa Verde" },
    { value: "Casa Verde Alta", label: "Casa Verde Alta" },
    { value: "Casa Verde Baixa", label: "Casa Verde Baixa" },
    { value: "Casa Verde Média", label: "Casa Verde Média" },
    { value: "Catumbi", label: "Catumbi" },
    { value: "Caxingui", label: "Caxingui" },
    { value: "Centro", label: "Centro" },
    { value: "Cerqueira César", label: "Cerqueira César" },
    { value: "Chácara Bandeirantes", label: "Chácara Bandeirantes" },
    { value: "Chácara Belenzinho", label: "Chácara Belenzinho" },
    { value: "Chácara Bosque do Sol", label: "Chácara Bosque do Sol" },
    { value: "Chácara Califórnia", label: "Chácara Califórnia" },
    { value: "Chácara Cocaia", label: "Chácara Cocaia" },
    { value: "Chácara Cruzeiro do Sul", label: "Chácara Cruzeiro do Sul" },
    { value: "Chácara da Enseada", label: "Chácara da Enseada" },
    { value: "Chácara das Corujas", label: "Chácara das Corujas" },
    { value: "Chácara do Conde", label: "Chácara do Conde" },
    { value: "Chácara do Sol", label: "Chácara do Sol" },
    { value: "Chácara Dona Olívia", label: "Chácara Dona Olívia" },
    { value: "Chácara Figueira Grande", label: "Chácara Figueira Grande" },
    { value: "Chácara Flora", label: "Chácara Flora" },
    { value: "Chácara Flórida", label: "Chácara Flórida" },
    { value: "Chácara Gaivotas", label: "Chácara Gaivotas" },
    { value: "Chácara Inglesa", label: "Chácara Inglesa" },
    { value: "Chácara Itaim", label: "Chácara Itaim" },
    { value: "Chácara Japonesa", label: "Chácara Japonesa" },
    { value: "Chácara Jaraguá", label: "Chácara Jaraguá" },
    { value: "Chácara Mafalda", label: "Chácara Mafalda" },
    { value: "Chácara Maria Trindade", label: "Chácara Maria Trindade" },
    { value: "Chácara Meyer", label: "Chácara Meyer" },
    { value: "Chácara Monte Alegre", label: "Chácara Monte Alegre" },
    { value: "Chácara Nani", label: "Chácara Nani" },
    { value: "Chácara Nossa Senhora Aparecida", label: "Chácara Nossa Senhora Aparecida" },
    { value: "Chácara Nossa Senhora do Bom Conselho", label: "Chácara Nossa Senhora do Bom Conselho" },
    { value: "Chácara Pouso Alegre", label: "Chácara Pouso Alegre" },
    { value: "Chácara Santa Etelvina", label: "Chácara Santa Etelvina" },
    { value: "Chácara Santa Maria", label: "Chácara Santa Maria" },
    { value: "Chácara Santana", label: "Chácara Santana" },
    { value: "Chácara Santo Antônio", label: "Chácara Santo Antônio" },
    { value: "Chácara Santo Hubertus", label: "Chácara Santo Hubertus" },
    { value: "Chácara São João", label: "Chácara São João" },
    { value: "Chácara São Luís", label: "Chácara São Luís" },
    { value: "Chácara São Silvestre", label: "Chácara São Silvestre" },
    { value: "Chácara Seis de Outubro", label: "Chácara Seis de Outubro" },
    { value: "Chácara Tatuapé", label: "Chácara Tatuapé" },
    { value: "Chácara Três Meninas", label: "Chácara Três Meninas" },
    { value: "Chácara Vista Alegre", label: "Chácara Vista Alegre" },
    { value: "Chora Menino", label: "Chora Menino" },
    { value: "Chs Lago Grande", label: "Chs Lago Grande" },
    { value: "Cidade Ademar", label: "Cidade Ademar" },
    { value: "Cidade Antônio Estêvão Carvalho", label: "Cidade Antônio Estêvão Carvalho" },
    { value: "Cidade Auxiliadora", label: "Cidade Auxiliadora" },
    { value: "Cidade Centenário", label: "Cidade Centenário" },
    { value: "Cidade Continental", label: "Cidade Continental" },
    { value: "Cidade D'abril", label: "Cidade D’abril" },
    { value: "Cidade de Deus", label: "Cidade de Deus" },
    { value: "Cidade Domitila", label: "Cidade Domitila" },
    { value: "Cidade dos Bandeirantes", label: "Cidade dos Bandeirantes" },
    { value: "Cidade Dutra", label: "Cidade Dutra" },
    { value: "Cidade Ipava", label: "Cidade Ipava" },
    { value: "Cidade Jardim", label: "Cidade Jardim" },
    { value: "Cidade Júlia", label: "Cidade Júlia" },
    { value: "Cidade Kemel", label: "Cidade Kemel" },
    { value: "Cidade Líder", label: "Cidade Líder" },
    { value: "Cidade Luz", label: "Cidade Luz" },
    { value: "Cidade Mãe Ceu", label: "Cidade Mãe Ceu" },
    { value: "Cidade Mãe do Ceu", label: "Cidade Mãe do Ceu" },
    { value: "Cidade Monções", label: "Cidade Monções" },
    { value: "Cidade Nitro Operária", label: "Cidade Nitro Operária" },
    { value: "Cidade Nitro Química", label: "Cidade Nitro Química" },
    { value: "Cidade Nova América", label: "Cidade Nova América" },
    { value: "Cidade Nova Heliopolis", label: "Cidade Nova Heliopolis" },
    { value: "Cidade Nova São Miguel", label: "Cidade Nova São Miguel" },
    { value: "Cidade Patriarca", label: "Cidade Patriarca" },
    { value: "Cidade Popular", label: "Cidade Popular" },
    { value: "Cidade São Francisco", label: "Cidade São Francisco" },
    { value: "Cidade São Mateus", label: "Cidade São Mateus" },
    { value: "Cidade São Miguel", label: "Cidade São Miguel" },
    { value: "Cidade Satélite Santa Bárbara", label: "Cidade Satélite Santa Bárbara" },
    { value: "Cidade Tiradentes", label: "Cidade Tiradentes" },
    { value: "Cidade Vargas", label: "Cidade Vargas" },
    { value: "Cipó do Meio", label: "Cipó do Meio" },
    { value: "City América", label: "City América" },
    { value: "Cohab Monet", label: "Cohab Monet" },
    { value: "Colônia", label: "Colônia" },
    { value: "Condomínio Jequirituba", label: "Condomínio Jequirituba" },
    { value: "Conjunto City Jaraguá", label: "Conjunto City Jaraguá" },
    { value: "Conjunto Habitacional Águia de Haia", label: "Conjunto Habitacional Águia de Haia" },
    { value: "Conjunto Habitacional Área E Carvalho", label: "Conjunto Habitacional Área E Carvalho" },
    { value: "Conjunto Habitacional Barreira Grande", label: "Conjunto Habitacional Barreira Grande" },
    { value: "Conjunto Habitacional Barro Branco I", label: "Conjunto Habitacional Barro Branco I" },
    { value: "Conjunto Habitacional Barro Branco II", label: "Conjunto Habitacional Barro Branco II" },
    { value: "Conjunto Habitacional Brigadeiro Eduardo Gomes", label: "Conjunto Habitacional Brigadeiro Eduardo Gomes" },
    { value: "Conjunto Habitacional Brigadeiro Faria Lima", label: "Conjunto Habitacional Brigadeiro Faria Lima" },
    { value: "Conjunto Habitacional Castro Alves", label: "Conjunto Habitacional Castro Alves" },
    { value: "Conjunto Habitacional Fazenda do Carmo", label: "Conjunto Habitacional Fazenda do Carmo" },
    { value: "Conjunto Habitacional Inácio Monteiro", label: "Conjunto Habitacional Inácio Monteiro" },
    { value: "Conjunto Habitacional Instituto Adventista", label: "Conjunto Habitacional Instituto Adventista" },
    { value: "Conjunto Habitacional Jardim São Bento", label: "Conjunto Habitacional Jardim São Bento" },
    { value: "Conjunto Habitacional Jova Rural", label: "Conjunto Habitacional Jova Rural" },
    { value: "Conjunto Habitacional Juscelino Kubitschek", label: "Conjunto Habitacional Juscelino Kubitschek" },
    { value: "Conjunto Habitacional Marechal Mascarenhas Morais", label: "Conjunto Habitacional Marechal Mascarenhas Morais" },
    { value: "Conjunto Habitacional Padre José de Anchieta", label: "Conjunto Habitacional Padre José de Anchieta" },
    { value: "Conjunto Habitacional Padre Manoel da Nóbrega", label: "Conjunto Habitacional Padre Manoel da Nóbrega" },
    { value: "Conjunto Habitacional Padre Manoel de Paiva", label: "Conjunto Habitacional Padre Manoel de Paiva" },
    { value: "Conjunto Habitacional Parque Valo Velho Ii", label: "Conjunto Habitacional Parque Valo Velho Ii" },
    { value: "Conjunto Habitacional Pirajussara", label: "Conjunto Habitacional Pirajussara" },
    { value: "Conjunto Habitacional Recanto dos Humildes", label: "Conjunto Habitacional Recanto dos Humildes" },
    { value: "Conjunto Habitacional Santa Etelvina II", label: "Conjunto Habitacional Santa Etelvina II" },
    { value: "Conjunto Habitacional Santa Etelvina III", label: "Conjunto Habitacional Santa Etelvina III" },
    { value: "Conjunto Habitacional Sítio Conceição", label: "Conjunto Habitacional Sítio Conceição" },
    { value: "Conjunto Habitacional Teotônio Vilela", label: "Conjunto Habitacional Teotônio Vilela" },
    { value: "Conjunto Habitacional Turística", label: "Conjunto Habitacional Turística" },
    { value: "Conjunto Habitacional Vila Nova Cachoeirinha", label: "Conjunto Habitacional Vila Nova Cachoeirinha" },
    { value: "Conjunto Promorar Estrada da Parada", label: "Conjunto Promorar Estrada da Parada" },
    { value: "Conjunto Promorar Raposo Tavares", label: "Conjunto Promorar Raposo Tavares" },
    { value: "Conjunto Promorar Rio Claro", label: "Conjunto Promorar Rio Claro" },
    { value: "Conjunto Promorar São Luís", label: "Conjunto Promorar São Luís" },
    { value: "Conjunto Promorar Sapopemba", label: "Conjunto Promorar Sapopemba" },
    { value: "Conjunto Promorar Vila Maria Iii", label: "Conjunto Promorar Vila Maria Iii" },
    { value: "Conjunto Residencial Alpes do Jaraguá", label: "Conjunto Residencial Alpes do Jaraguá" },
    { value: "Conjunto Residencial Butantã", label: "Conjunto Residencial Butantã" },
    { value: "Conjunto Residencial Elísio Teixeira Leite", label: "Conjunto Residencial Elísio Teixeira Leite" },
    { value: "Conjunto Residencial Ingai", label: "Conjunto Residencial Ingai" },
    { value: "Conjunto Residencial Jardim Canaã", label: "Conjunto Residencial Jardim Canaã" },
    { value: "Conjunto Residencial José Bonifácio", label: "Conjunto Residencial José Bonifácio" },
    { value: "Conjunto Residencial Morada do Sol", label: "Conjunto Residencial Morada do Sol" },
    { value: "Conjunto Residencial Novo Pacaembu", label: "Conjunto Residencial Novo Pacaembu" },
    { value: "Conjunto Residencial Paraíso", label: "Conjunto Residencial Paraíso" },
    { value: "Conjunto Residencial Prestes Maia", label: "Conjunto Residencial Prestes Maia" },
    { value: "Conjunto Residencial Sabará - Campo Grande", label: "Conjunto Residencial Sabará – Campo Grande" },
    { value: "Conjunto Residencial Salvador Tolezani", label: "Conjunto Residencial Salvador Tolezani" },
    { value: "Conjunto Residencial Santa Terezinha", label: "Conjunto Residencial Santa Terezinha" },
    { value: "Conjunto Residencial Sítio Oratório", label: "Conjunto Residencial Sítio Oratório" },
    { value: "Conjunto Residencial Vista Verde", label: "Conjunto Residencial Vista Verde" },
    { value: "Consolação", label: "Consolação" },
    { value: "Copacabana", label: "Copacabana" },
    { value: "Cupecê", label: "Cupecê" },
    { value: "Cursino", label: "Cursino" },
    { value: "Educandário", label: "Educandário" },
    { value: "Eldorado", label: "Eldorado" },
    { value: "Embura", label: "Embura" },
    { value: "Engenheiro Goulart", label: "Engenheiro Goulart" },
    { value: "Engenheiro Marsilac", label: "Engenheiro Marsilac" },
    { value: "Ermelino Matarazzo", label: "Ermelino Matarazzo" },
    { value: "Estância Jaraguá", label: "Estância Jaraguá" },
    { value: "Estância Mirim", label: "Estância Mirim" },
    { value: "Estância Tangará", label: "Estância Tangará" },
    { value: "Fazenda Aricanduva", label: "Fazenda Aricanduva" },
    { value: "Fazenda da Juta", label: "Fazenda da Juta" },
    { value: "Fazenda Itaim", label: "Fazenda Itaim" },
    { value: "Fazenda Morumbi", label: "Fazenda Morumbi" },
    { value: "Ferreira", label: "Ferreira" },
    { value: "Flor da Cantareira", label: "Flor da Cantareira" },
    { value: "Freguesia do O", label: "Freguesia do O" },
    { value: "Furnas", label: "Furnas" },
    { value: "Gleba do Pêssego", label: "Gleba do Pêssego" },
    { value: "Granja Julieta", label: "Granja Julieta" },
    { value: "Granja Nossa Senhora Aparecida", label: "Granja Nossa Senhora Aparecida" },
    { value: "Guacuri", label: "Guacuri" },
    { value: "Guaianazes", label: "Guaianazes" },
    { value: "Guaiauna", label: "Guaiauna" },
    { value: "Guapira", label: "Guapira" },
    { value: "Guarapiranga", label: "Guarapiranga" },
    { value: "Higienópolis", label: "Higienópolis" },
    { value: "Horto", label: "Horto" },
    { value: "Horto Florestal", label: "Horto Florestal" },
    { value: "Ibirapuera", label: "Ibirapuera" },
    { value: "Imirim", label: "Imirim" },
    { value: "Indianópolis", label: "Indianópolis" },
    { value: "Instituto de Previdência", label: "Instituto de Previdência" },
    { value: "Interlagos", label: "Interlagos" },
    { value: "Ipiranga", label: "Ipiranga" },
    { value: "Itaberaba", label: "Itaberaba" },
    { value: "Itaim Bibi", label: "Itaim Bibi" },
    { value: "Itaim Paulista", label: "Itaim Paulista" },
    { value: "Itaquera", label: "Itaquera" },
    { value: "Itupu", label: "Itupu" },
    { value: "Jabaquara", label: "Jabaquara" },
    { value: "Jaçanã", label: "Jaçanã" },
    { value: "Jaguare", label: "Jaguare" },
    { value: "Jaraguá", label: "Jaraguá" },
    { value: "Jardim Adelaide", label: "Jardim Adelaide" },
    { value: "Jardim Adelfiore", label: "Jardim Adelfiore" },
    { value: "Jardim Adélia", label: "Jardim Adélia" },
    { value: "Jardim Adhemar de Barros", label: "Jardim Adhemar de Barros" },
    { value: "Jardim Adutora", label: "Jardim Adutora" },
    { value: "Jardim Aeroporto", label: "Jardim Aeroporto" },
    { value: "Jardim Aida", label: "Jardim Aida" },
    { value: "Jardim Aimoré", label: "Jardim Aimoré" },
    { value: "Jardim Aladim", label: "Jardim Aladim" },
    { value: "Jardim Alfredo", label: "Jardim Alfredo" },
    { value: "Jardim Almanara", label: "Jardim Almanara" },
    { value: "Jardim Almeida", label: "Jardim Almeida" },
    { value: "Jardim Almeida Prado", label: "Jardim Almeida Prado" },
    { value: "Jardim Alpino", label: "Jardim Alpino" },
    { value: "Jardim Alto Alegre", label: "Jardim Alto Alegre" },
    { value: "Jardim Alto Paulistano", label: "Jardim Alto Paulistano" },
    { value: "Jardim Alto Pedroso", label: "Jardim Alto Pedroso" },
    { value: "Jardim Alvina", label: "Jardim Alvina" },
    { value: "Jardim Alviverde", label: "Jardim Alviverde" },
    { value: "Jardim Alvorada", label: "Jardim Alvorada" },
    { value: "Jardim Alzira", label: "Jardim Alzira" },
    { value: "Jardim Amália", label: "Jardim Amália" },
    { value: "Jardim Amaralina", label: "Jardim Amaralina" },
    { value: "Jardim América", label: "Jardim América" },
    { value: "Jardim América da Penha", label: "Jardim América da Penha" },
    { value: "Jardim Ampliação", label: "Jardim Ampliação" },
    { value: "Jardim Ana Lúcia", label: "Jardim Ana Lúcia" },
    { value: "Jardim Ana Maria", label: "Jardim Ana Maria" },
    { value: "Jardim Ana Rosa", label: "Jardim Ana Rosa" },
    { value: "Jardim Anália Franco", label: "Jardim Anália Franco" },
    { value: "Jardim Andaraí", label: "Jardim Andaraí" },
    { value: "Jardim Ângela", label: "Jardim Ângela" },
    { value: "Jardim Angelina", label: "Jardim Angelina" },
    { value: "Jardim Anhanguera", label: "Jardim Anhanguera" },
    { value: "Jardim Antártica", label: "Jardim Antártica" },
    { value: "Jardim Aparecida", label: "Jardim Aparecida" },
    { value: "Jardim Apuana", label: "Jardim Apuana" },
    { value: "Jardim Apura", label: "Jardim Apura" },
    { value: "Jardim Aracati", label: "Jardim Aracati" },
    { value: "Jardim Arantes", label: "Jardim Arantes" },
    { value: "Jardim Arco-íris", label: "Jardim Arco-íris" },
    { value: "Jardim Aricanduva", label: "Jardim Aricanduva" },
    { value: "Jardim Arize", label: "Jardim Arize" },
    { value: "Jardim Arizona", label: "Jardim Arizona" },
    { value: "Jardim Arpoador", label: "Jardim Arpoador" },
    { value: "Jardim Artur Alvim", label: "Jardim Artur Alvim" },
    { value: "Jardim Assunção", label: "Jardim Assunção" },
    { value: "Jardim Ataliba Leonel", label: "Jardim Ataliba Leonel" },
    { value: "Jardim Atlântico", label: "Jardim Atlântico" },
    { value: "Jardim Augusta", label: "Jardim Augusta" },
    { value: "Jardim Augusto", label: "Jardim Augusto" },
    { value: "Jardim Áurea", label: "Jardim Áurea" },
    { value: "Jardim Aurélia", label: "Jardim Aurélia" },
    { value: "Jardim Aurélio", label: "Jardim Aurélio" },
    { value: "Jardim Aurora", label: "Jardim Aurora" },
    { value: "Jardim Avelino", label: "Jardim Avelino" },
    { value: "Jardim Avenida", label: "Jardim Avenida" },
    { value: "Jardim Azano I", label: "Jardim Azano I" },
    { value: "Jardim Azano II", label: "Jardim Azano II" },
    { value: "Jardim Bandeirante", label: "Jardim Bandeirante" },
    { value: "Jardim Bandeirantes", label: "Jardim Bandeirantes" },
    { value: "Jardim Bartira", label: "Jardim Bartira" },
    { value: "Jardim Batalha", label: "Jardim Batalha" },
    { value: "Jardim Beatriz", label: "Jardim Beatriz" },
    { value: "Jardim Bela Vista", label: "Jardim Bela Vista" },
    { value: "Jardim Belaura", label: "Jardim Belaura" },
    { value: "Jardim Belcito", label: "Jardim Belcito" },
    { value: "Jardim Belém", label: "Jardim Belém" },
    { value: "Jardim Bélgica", label: "Jardim Bélgica" },
    { value: "Jardim Benfica", label: "Jardim Benfica" },
    { value: "Jardim Bichinhos", label: "Jardim Bichinhos" },
    { value: "Jardim Boa Esperança", label: "Jardim Boa Esperança" },
    { value: "Jardim Boa Vista", label: "Jardim Boa Vista" },
    { value: "Jardim Bom Clima", label: "Jardim Bom Clima" },
    { value: "Jardim Bom Pastor", label: "Jardim Bom Pastor" },
    { value: "Jardim Bom Refúgio", label: "Jardim Bom Refúgio" },
    { value: "Jardim Bonfiglioli", label: "Jardim Bonfiglioli" },
    { value: "Jardim Bonito", label: "Jardim Bonito" },
    { value: "Jardim Borba Gato", label: "Jardim Borba Gato" },
    { value: "Jardim Botucatu", label: "Jardim Botucatu" },
    { value: "Jardim Bransley", label: "Jardim Bransley" },
    { value: "Jardim Brasil", label: "Jardim Brasil" },
    { value: "Jardim Brasil Novo", label: "Jardim Brasil Novo" },
    { value: "Jardim Brasília", label: "Jardim Brasília" },
    { value: "Jardim Britânia", label: "Jardim Britânia" },
    { value: "Jardim Bronzato", label: "Jardim Bronzato" },
    { value: "Jardim Buriti", label: "Jardim Buriti" },
    { value: "Jardim Caboré", label: "Jardim Caboré" },
    { value: "Jardim Cabuçu", label: "Jardim Cabuçu" },
    { value: "Jardim Cachoeira", label: "Jardim Cachoeira" },
    { value: "Jardim Caguassu", label: "Jardim Caguassu" },
    { value: "Jardim Camargo Novo", label: "Jardim Camargo Novo" },
    { value: "Jardim Cambará", label: "Jardim Cambará" },
    { value: "Jardim Campinas", label: "Jardim Campinas" },
    { value: "Jardim Campo dos Ferreiros", label: "Jardim Campo dos Ferreiros" },
    { value: "Jardim Campo Grande", label: "Jardim Campo Grande" },
    { value: "Jardim Campo Limpo", label: "Jardim Campo Limpo" },
    { value: "Jardim Campos", label: "Jardim Campos" },
    { value: "Jardim Capão Redondo", label: "Jardim Capão Redondo" },
    { value: "Jardim Capela", label: "Jardim Capela" },
    { value: "Jardim Capelinha", label: "Jardim Capelinha" },
    { value: "Jardim Caravelas", label: "Jardim Caravelas" },
    { value: "Jardim Carlu", label: "Jardim Carlu" },
    { value: "Jardim Carolina", label: "Jardim Carolina" },
    { value: "Jardim Carombe", label: "Jardim Carombe" },
    { value: "Jardim Casa Grande", label: "Jardim Casa Grande" },
    { value: "Jardim Casa Pintada", label: "Jardim Casa Pintada" },
    { value: "Jardim Casablanca", label: "Jardim Casablanca" },
    { value: "Jardim Castelo", label: "Jardim Castelo" },
    { value: "Jardim Castro Alves", label: "Jardim Castro Alves" },
    { value: "Jardim Catanduva", label: "Jardim Catanduva" },
    { value: "Jardim Catarina", label: "Jardim Catarina" },
    { value: "Jardim Cecy", label: "Jardim Cecy" },
    { value: "Jardim Celeste", label: "Jardim Celeste" },
    { value: "Jardim Célia", label: "Jardim Célia" },
    { value: "Jardim Centenário", label: "Jardim Centenário" },
    { value: "Jardim Cibele", label: "Jardim Cibele" },
    { value: "Jardim Cidade Pirituba", label: "Jardim Cidade Pirituba" },
    { value: "Jardim Cidalia", label: "Jardim Cidalia" },
    { value: "Jardim Cinco de Julho", label: "Jardim Cinco de Julho" },
    { value: "Jardim Clara Regina", label: "Jardim Clara Regina" },
    { value: "Jardim Clarice", label: "Jardim Clarice" },
    { value: "Jardim Cláudia", label: "Jardim Cláudia" },
    { value: "Jardim Cleide", label: "Jardim Cleide" },
    { value: "Jardim Clímax", label: "Jardim Clímax" },
    { value: "Jardim Cliper", label: "Jardim Cliper" },
    { value: "Jardim Coimbra", label: "Jardim Coimbra" },
    { value: "Jardim Colibri", label: "Jardim Colibri" },
    { value: "Jardim Colombo", label: "Jardim Colombo" },
    { value: "Jardim Colonial", label: "Jardim Colonial" },
    { value: "Jardim Colorado", label: "Jardim Colorado" },
    { value: "Jardim Comercial", label: "Jardim Comercial" },
    { value: "Jardim Concórdia", label: "Jardim Concórdia" },
    { value: "Jardim Conquista", label: "Jardim Conquista" },
    { value: "Jardim Consórcio", label: "Jardim Consórcio" },
    { value: "Jardim Copacabana", label: "Jardim Copacabana" },
    { value: "Jardim Cordeiro", label: "Jardim Cordeiro" },
    { value: "Jardim Corisco", label: "Jardim Corisco" },
    { value: "Jardim Cotching", label: "Jardim Cotching" },
    { value: "Jardim Cotiana", label: "Jardim Cotiana" },
    { value: "Jardim Cotinha", label: "Jardim Cotinha" },
    { value: "Jardim Cris", label: "Jardim Cris" },
    { value: "Jardim Cristal", label: "Jardim Cristal" },
    { value: "Jardim Cristália", label: "Jardim Cristália" },
    { value: "Jardim Cristina", label: "Jardim Cristina" },
    { value: "Jardim Cruzeiro", label: "Jardim Cruzeiro" },
    { value: "Jardim D'abril", label: "Jardim D’abril" },
    { value: "Jardim da Cachoeira", label: "Jardim da Cachoeira" },
    { value: "Jardim da Campina", label: "Jardim da Campina" },
    { value: "Jardim da Conquista", label: "Jardim da Conquista" },
    { value: "Jardim da Glória", label: "Jardim da Glória" },
    { value: "Jardim da Laranjeira", label: "Jardim da Laranjeira" },
    { value: "Jardim da Pedreira", label: "Jardim da Pedreira" },
    { value: "Jardim da Saúde", label: "Jardim da Saúde" },
    { value: "Jardim Dalmo", label: "Jardim Dalmo" },
    { value: "Jardim Damasceno", label: "Jardim Damasceno" },
    { value: "Jardim Danfer", label: "Jardim Danfer" },
    { value: "Jardim das Acácias", label: "Jardim das Acácias" },
    { value: "Jardim das Bandeiras", label: "Jardim das Bandeiras" },
    { value: "Jardim das Camélias", label: "Jardim das Camélias" },
    { value: "Jardim das Carmelitas", label: "Jardim das Carmelitas" },
    { value: "Jardim das Esmeraldas", label: "Jardim das Esmeraldas" },
    { value: "Jardim das Flores", label: "Jardim das Flores" },
    { value: "Jardim das Fontes", label: "Jardim das Fontes" },
    { value: "Jardim das Graças", label: "Jardim das Graças" },
    { value: "Jardim das Imbuias", label: "Jardim das Imbuias" },
    { value: "Jardim das Laranjeiras", label: "Jardim das Laranjeiras" },
    { value: "Jardim das Oliveiras", label: "Jardim das Oliveiras" },
    { value: "Jardim das Palmas", label: "Jardim das Palmas" },
    { value: "Jardim das Palmeiras", label: "Jardim das Palmeiras" },
    { value: "Jardim das Pedras", label: "Jardim das Pedras" },
    { value: "Jardim das Praias", label: "Jardim das Praias" },
    { value: "Jardim das Rosas", label: "Jardim das Rosas" },
    { value: "Jardim das Vertentes", label: "Jardim das Vertentes" },
    { value: "Jardim Daysy", label: "Jardim Daysy" },
    { value: "Jardim de Lorenzo", label: "Jardim de Lorenzo" },
    { value: "Jardim Denise", label: "Jardim Denise" },
    { value: "Jardim Dinar", label: "Jardim Dinar" },
    { value: "Jardim Dinorah", label: "Jardim Dinorah" },
    { value: "Jardim Dionísio", label: "Jardim Dionísio" },
    { value: "Jardim do Alto", label: "Jardim do Alto" },
    { value: "Jardim do Campo", label: "Jardim do Campo" },
    { value: "Jardim do Carmo", label: "Jardim do Carmo" },
    { value: "Jardim do Centro", label: "Jardim do Centro" },
    { value: "Jardim do Colégio", label: "Jardim do Colégio" },
    { value: "Jardim do Divino", label: "Jardim do Divino" },
    { value: "Jardim do Lago", label: "Jardim do Lago" },
    { value: "Jardim do Tiro", label: "Jardim do Tiro" },
    { value: "Jardim Dom Bosco", label: "Jardim Dom Bosco" },
    { value: "Jardim Dom Fernando", label: "Jardim Dom Fernando" },
    { value: "Jardim Dom José", label: "Jardim Dom José" },
    { value: "Jardim Domitila", label: "Jardim Domitila" },
    { value: "Jardim Dona Deolinda", label: "Jardim Dona Deolinda" },
    { value: "Jardim Dona-sinha", label: "Jardim Dona-sinha" },
    { value: "Jardim Donaria", label: "Jardim Donaria" },
    { value: "Jardim dos Álamos", label: "Jardim dos Álamos" },
    { value: "Jardim dos Bichinhos", label: "Jardim dos Bichinhos" },
    { value: "Jardim dos Cataldis", label: "Jardim dos Cataldis" },
    { value: "Jardim dos Estados", label: "Jardim dos Estados" },
    { value: "Jardim dos Eucaliptos", label: "Jardim dos Eucaliptos" },
    { value: "Jardim dos Francos", label: "Jardim dos Francos" },
    { value: "Jardim dos Ipês", label: "Jardim dos Ipês" },
    { value: "Jardim dos Lagos", label: "Jardim dos Lagos" },
    { value: "Jardim dos Manacás", label: "Jardim dos Manacás" },
    { value: "Jardim dos Pereiras", label: "Jardim dos Pereiras" },
    { value: "Jardim dos Prados", label: "Jardim dos Prados" },
    { value: "Jardim Dracena", label: "Jardim Dracena" },
    { value: "Jardim Duprat", label: "Jardim Duprat" },
    { value: "Jardim Edda", label: "Jardim Edda" },
    { value: "Jardim Edi", label: "Jardim Edi" },
    { value: "Jardim Edilene", label: "Jardim Edilene" },
    { value: "Jardim Edith", label: "Jardim Edith" },
    { value: "Jardim Educandário", label: "Jardim Educandário" },
    { value: "Jardim Egle", label: "Jardim Egle" },
    { value: "Jardim Elba", label: "Jardim Elba" },
    { value: "Jardim Eledy", label: "Jardim Eledy" },
    { value: "Jardim Eliana", label: "Jardim Eliana" },
    { value: "Jardim Eliane", label: "Jardim Eliane" },
    { value: "Jardim Elisa Maria", label: "Jardim Elisa Maria" },
    { value: "Jardim Elísio", label: "Jardim Elísio" },
    { value: "Jardim Eliza", label: "Jardim Eliza" },
    { value: "Jardim Elizabeth", label: "Jardim Elizabeth" },
    { value: "Jardim Ellus", label: "Jardim Ellus" },
    { value: "Jardim Elza", label: "Jardim Elza" },
    { value: "Jardim Ernestina", label: "Jardim Ernestina" },
    { value: "Jardim Esmeralda", label: "Jardim Esmeralda" },
    { value: "Jardim Ester", label: "Jardim Ester" },
    { value: "Jardim Ester Yolanda", label: "Jardim Ester Yolanda" },
    { value: "Jardim Estrela D'alva", label: "Jardim Estrela D’alva" },
    { value: "Jardim Etelvina", label: "Jardim Etelvina" },
    { value: "Jardim Europa", label: "Jardim Europa" },
    { value: "Jardim Eva", label: "Jardim Eva" },
    { value: "Jardim Everest", label: "Jardim Everest" },
    { value: "Jardim Fanganiello", label: "Jardim Fanganiello" },
    { value: "Jardim Faria Lima", label: "Jardim Faria Lima" },
    { value: "Jardim Felicidade", label: "Jardim Felicidade" },
    { value: "Jardim Fernandes", label: "Jardim Fernandes" },
    { value: "Jardim Figueira Grande", label: "Jardim Figueira Grande" },
    { value: "Jardim Filhos da Terra", label: "Jardim Filhos da Terra" },
    { value: "Jardim Filhos Terra", label: "Jardim Filhos Terra" },
    { value: "Jardim Flor de Maio", label: "Jardim Flor de Maio" },
    { value: "Jardim Floresta", label: "Jardim Floresta" },
    { value: "Jardim Flórida Paulista", label: "Jardim Flórida Paulista" },
    { value: "Jardim Fluminense", label: "Jardim Fluminense" },
    { value: "Jardim Fontalis", label: "Jardim Fontalis" },
    { value: "Jardim Fonte do Morumbi", label: "Jardim Fonte do Morumbi" },
    { value: "Jardim Franca", label: "Jardim Franca" },
    { value: "Jardim Francisco Mendes", label: "Jardim Francisco Mendes" },
    { value: "Jardim Fraternidade", label: "Jardim Fraternidade" },
    { value: "Jardim Fujihara", label: "Jardim Fujihara" },
    { value: "Jardim Galli", label: "Jardim Galli" },
    { value: "Jardim Germania", label: "Jardim Germania" },
    { value: "Jardim Gianetti", label: "Jardim Gianetti" },
    { value: "Jardim Gilda Maria", label: "Jardim Gilda Maria" },
    { value: "Jardim Glória", label: "Jardim Glória" },
    { value: "Jardim Gonzaga", label: "Jardim Gonzaga" },
    { value: "Jardim Grimaldi", label: "Jardim Grimaldi" },
    { value: "Jardim Guacuri", label: "Jardim Guacuri" },
    { value: "Jardim Guaianazes", label: "Jardim Guaianazes" },
    { value: "Jardim Guairaca", label: "Jardim Guairaca" },
    { value: "Jardim Guanabara", label: "Jardim Guanabara" },
    { value: "Jardim Guanca", label: "Jardim Guanca" },
    { value: "Jardim Guanhembu", label: "Jardim Guanhembu" },
    { value: "Jardim Guapira", label: "Jardim Guapira" },
    { value: "Jardim Guarani", label: "Jardim Guarani" },
    { value: "Jardim Guarapiranga", label: "Jardim Guarapiranga" },
    { value: "Jardim Guarau", label: "Jardim Guarau" },
    { value: "Jardim Guarujá", label: "Jardim Guarujá" },
    { value: "Jardim Guedala", label: "Jardim Guedala" },
    { value: "Jardim Guiomar", label: "Jardim Guiomar" },
    { value: "Jardim Gustavo", label: "Jardim Gustavo" },
    { value: "Jardim Hadad", label: "Jardim Hadad" },
    { value: "Jardim Haia do Carrão", label: "Jardim Haia do Carrão" },
    { value: "Jardim Hebrom", label: "Jardim Hebrom" },
    { value: "Jardim Helena", label: "Jardim Helena" },
    { value: "Jardim Helga", label: "Jardim Helga" },
    { value: "Jardim Helian", label: "Jardim Helian" },
    { value: "Jardim Heliomar", label: "Jardim Heliomar" },
    { value: "Jardim Heloísa", label: "Jardim Heloísa" },
    { value: "Jardim Hercilia", label: "Jardim Hercilia" },
    { value: "Jardim Herculano", label: "Jardim Herculano" },
    { value: "Jardim Herplin", label: "Jardim Herplin" },
    { value: "Jardim Hípico", label: "Jardim Hípico" },
    { value: "Jardim Humaitá", label: "Jardim Humaitá" },
    { value: "Jardim Iae", label: "Jardim Iae" },
    { value: "Jardim Ibirapuera", label: "Jardim Ibirapuera" },
    { value: "Jardim Ibiratiba", label: "Jardim Ibiratiba" },
    { value: "Jardim Ibitirama", label: "Jardim Ibitirama" },
    { value: "Jardim Icaraí", label: "Jardim Icaraí" },
    { value: "Jardim Ida Guedes", label: "Jardim Ida Guedes" },
    { value: "Jardim Ideal", label: "Jardim Ideal" },
    { value: "Jardim Iguatemi", label: "Jardim Iguatemi" },
    { value: "Jardim Imbé", label: "Jardim Imbé" },
    { value: "Jardim Imperador", label: "Jardim Imperador" },
    { value: "Jardim Imperial", label: "Jardim Imperial" },
    { value: "Jardim Império", label: "Jardim Império" },
    { value: "Jardim Indaiá", label: "Jardim Indaiá" },
    { value: "Jardim Independência", label: "Jardim Independência" },
    { value: "Jardim Ingá", label: "Jardim Ingá" },
    { value: "Jardim Internacional", label: "Jardim Internacional" },
    { value: "Jardim Ipanema", label: "Jardim Ipanema" },
    { value: "Jardim Ipe", label: "Jardim Ipe" },
    { value: "Jardim Iporá", label: "Jardim Iporá" },
    { value: "Jardim Iporanga", label: "Jardim Iporanga" },
    { value: "Jardim Iracema", label: "Jardim Iracema" },
    { value: "Jardim Irapiranga", label: "Jardim Irapiranga" },
    { value: "Jardim Irene", label: "Jardim Irene" },
    { value: "Jardim Íris", label: "Jardim Íris" },
    { value: "Jardim Itacolomi", label: "Jardim Itacolomi" },
    { value: "Jardim Itajaí", label: "Jardim Itajaí" },
    { value: "Jardim Itália", label: "Jardim Itália" },
    { value: "Jardim Itaóca", label: "Jardim Itaóca" },
    { value: "Jardim Itapema", label: "Jardim Itapema" },
    { value: "Jardim Itapemirim", label: "Jardim Itapemirim" },
    { value: "Jardim Itapeva", label: "Jardim Itapeva" },
    { value: "Jardim Itápolis", label: "Jardim Itápolis" },
    { value: "Jardim Itapura", label: "Jardim Itapura" },
    { value: "Jardim Itatiaia", label: "Jardim Itatiaia" },
    { value: "Jardim Iv Centenário", label: "Jardim Iv Centenário" },
    { value: "Jardim Iva", label: "Jardim Iva" },
    { value: "Jardim Ivana", label: "Jardim Ivana" },
    { value: "Jardim Ivone", label: "Jardim Ivone" },
    { value: "Jardim Jabaquara", label: "Jardim Jabaquara" },
    { value: "Jardim Jaçanã", label: "Jardim Jaçanã" },
    { value: "Jardim Jamaica", label: "Jardim Jamaica" },
    { value: "Jardim Japão", label: "Jardim Japão" },
    { value: "Jardim Jaqueline", label: "Jardim Jaqueline" },
    { value: "Jardim Jaraguá", label: "Jardim Jaraguá" },
    { value: "Jardim Jaú", label: "Jardim Jaú" },
    { value: "Jardim Joamar", label: "Jardim Joamar" },
    { value: "Jardim Joana D'arc", label: "Jardim Joana D’arc" },
    { value: "Jardim João Xxiii", label: "Jardim João Xxiii" },
    { value: "Jardim Jordão", label: "Jardim Jordão" },
    { value: "Jardim Juá", label: "Jardim Juá" },
    { value: "Jardim Julieta", label: "Jardim Julieta" },
    { value: "Jardim Jurema", label: "Jardim Jurema" },
    { value: "Jardim Jussara", label: "Jardim Jussara" },
    { value: "Jardim Kagohara", label: "Jardim Kagohara" },
    { value: "Jardim Keralux", label: "Jardim Keralux" },
    { value: "Jardim Kika", label: "Jardim Kika" },
    { value: "Jardim Kioto", label: "Jardim Kioto" },
    { value: "Jardim Labitary", label: "Jardim Labitary" },
    { value: "Jardim Lajeado", label: "Jardim Lajeado" },
    { value: "Jardim Lallo", label: "Jardim Lallo" },
    { value: "Jardim Laone", label: "Jardim Laone" },
    { value: "Jardim Lapena", label: "Jardim Lapena" },
    { value: "Jardim Laranjeira", label: "Jardim Laranjeira" },
    { value: "Jardim Laura", label: "Jardim Laura" },
    { value: "Jardim Leila", label: "Jardim Leila" },
    { value: "Jardim Leme", label: "Jardim Leme" },
    { value: "Jardim Leni", label: "Jardim Leni" },
    { value: "Jardim Leonardo", label: "Jardim Leonardo" },
    { value: "Jardim Leônidas Moreira", label: "Jardim Leônidas Moreira" },
    { value: "Jardim Leonor", label: "Jardim Leonor" },
    { value: "Jardim Leonor Mendes de Barros", label: "Jardim Leonor Mendes de Barros" },
    { value: "Jardim Letícia", label: "Jardim Letícia" },
    { value: "Jardim Líbano", label: "Jardim Líbano" },
    { value: "Jardim Líder", label: "Jardim Líder" },
    { value: "Jardim Liderança", label: "Jardim Liderança" },
    { value: "Jardim Lídia", label: "Jardim Lídia" },
    { value: "Jardim Lilah", label: "Jardim Lilah" },
    { value: "Jardim Limoeiro", label: "Jardim Limoeiro" },
    { value: "Jardim Lisboa", label: "Jardim Lisboa" },
    { value: "Jardim Londrina", label: "Jardim Londrina" },
    { value: "Jardim Los Angeles", label: "Jardim Los Angeles" },
    { value: "Jardim Lourdes", label: "Jardim Lourdes" },
    { value: "Jardim Luanda", label: "Jardim Luanda" },
    { value: "Jardim Lucélia", label: "Jardim Lucélia" },
    { value: "Jardim Lúcia", label: "Jardim Lúcia" },
    { value: "Jardim Luciana", label: "Jardim Luciana" },
    { value: "Jardim Lucinda", label: "Jardim Lucinda" },
    { value: "Jardim Luisa", label: "Jardim Luisa" },
    { value: "Jardim Luso", label: "Jardim Luso" },
    { value: "Jardim Luzitania", label: "Jardim Luzitania" },
    { value: "Jardim Mabel", label: "Jardim Mabel" },
    { value: "Jardim Macedônia", label: "Jardim Macedônia" },
    { value: "Jardim Machado", label: "Jardim Machado" },
    { value: "Jardim Maggi", label: "Jardim Maggi" },
    { value: "Jardim Maia", label: "Jardim Maia" },
    { value: "Jardim Malia I", label: "Jardim Malia I" },
    { value: "Jardim Malia Ii", label: "Jardim Malia Ii" },
    { value: "Jardim Mangalot", label: "Jardim Mangalot" },
    { value: "Jardim Marabá", label: "Jardim Marabá" },
    { value: "Jardim Maracá", label: "Jardim Maracá" },
    { value: "Jardim Maracanã", label: "Jardim Maracanã" },
    { value: "Jardim Marajoara", label: "Jardim Marajoara" },
    { value: "Jardim Marcel", label: "Jardim Marcel" },
    { value: "Jardim Marco Paulo", label: "Jardim Marco Paulo" },
    { value: "Jardim Margarida", label: "Jardim Margarida" },
    { value: "Jardim Maria Amália", label: "Jardim Maria Amália" },
    { value: "Jardim Maria Augusta", label: "Jardim Maria Augusta" },
    { value: "Jardim Maria Duarte", label: "Jardim Maria Duarte" },
    { value: "Jardim Maria Emília", label: "Jardim Maria Emília" },
    { value: "Jardim Maria Estela", label: "Jardim Maria Estela" },
    { value: "Jardim Maria Lídia", label: "Jardim Maria Lídia" },
    { value: "Jardim Maria Lúcia", label: "Jardim Maria Lúcia" },
    { value: "Jardim Maria Luíza", label: "Jardim Maria Luíza" },
    { value: "Jardim Maria Nazaré", label: "Jardim Maria Nazaré" },
    { value: "Jardim Maria Rita", label: "Jardim Maria Rita" },
    { value: "Jardim Maria Sampaio", label: "Jardim Maria Sampaio" },
    { value: "Jardim Maria Virgínia", label: "Jardim Maria Virgínia" },
    { value: "Jardim Mariane", label: "Jardim Mariane" },
    { value: "Jardim Marilda", label: "Jardim Marilda" },
    { value: "Jardim Marília", label: "Jardim Marília" },
    { value: "Jardim Mariliza", label: "Jardim Mariliza" },
    { value: "Jardim Marilu", label: "Jardim Marilu" },
    { value: "Jardim Maringá", label: "Jardim Maringá" },
    { value: "Jardim Marisa", label: "Jardim Marisa" },
    { value: "Jardim Maristela", label: "Jardim Maristela" },
    { value: "Jardim Marpu", label: "Jardim Marpu" },
    { value: "Jardim Marquesa", label: "Jardim Marquesa" },
    { value: "Jardim Martini", label: "Jardim Martini" },
    { value: "Jardim Martinica", label: "Jardim Martinica" },
    { value: "Jardim Martins Silva", label: "Jardim Martins Silva" },
    { value: "Jardim Matarazzo", label: "Jardim Matarazzo" },
    { value: "Jardim Mazza", label: "Jardim Mazza" },
    { value: "Jardim Meliunas", label: "Jardim Meliunas" },
    { value: "Jardim Melo", label: "Jardim Melo" },
    { value: "Jardim Mimar", label: "Jardim Mimar" },
    { value: "Jardim Miragaia", label: "Jardim Miragaia" },
    { value: "Jardim Mirante", label: "Jardim Mirante" },
    { value: "Jardim Miriam", label: "Jardim Miriam" },
    { value: "Jardim Mitsutani", label: "Jardim Mitsutani" },
    { value: "Jardim Modelo", label: "Jardim Modelo" },
    { value: "Jardim Mônica", label: "Jardim Mônica" },
    { value: "Jardim Monjolo", label: "Jardim Monjolo" },
    { value: "Jardim Monte Alegre", label: "Jardim Monte Alegre" },
    { value: "Jardim Monte Azul", label: "Jardim Monte Azul" },
    { value: "Jardim Monte Belo", label: "Jardim Monte Belo" },
    { value: "Jardim Monte Kemel", label: "Jardim Monte Kemel" },
    { value: "Jardim Monte Verde", label: "Jardim Monte Verde" },
    { value: "Jardim Morais Prado", label: "Jardim Morais Prado" },
    { value: "Jardim Moreno", label: "Jardim Moreno" },
    { value: "Jardim Morro Verde", label: "Jardim Morro Verde" },
    { value: "Jardim Morumbi", label: "Jardim Morumbi" },
    { value: "Jardim Mutinga", label: "Jardim Mutinga" },
    { value: "Jardim Myrna", label: "Jardim Myrna" },
    { value: "Jardim Nadir", label: "Jardim Nadir" },
    { value: "Jardim Nair", label: "Jardim Nair" },
    { value: "Jardim Nakamura", label: "Jardim Nakamura" },
    { value: "Jardim Namba", label: "Jardim Namba" },
    { value: "Jardim Naufal", label: "Jardim Naufal" },
    { value: "Jardim Nazareth", label: "Jardim Nazareth" },
    { value: "Jardim Neide", label: "Jardim Neide" },
    { value: "Jardim Neila", label: "Jardim Neila" },
    { value: "Jardim Nélia", label: "Jardim Nélia" },
    { value: "Jardim Nélia Iv", label: "Jardim Nélia Iv" },
    { value: "Jardim Nelly", label: "Jardim Nelly" },
    { value: "Jardim Nice", label: "Jardim Nice" },
    { value: "Jardim Ninho Verde II", label: "Jardim Ninho Verde II" },
    { value: "Jardim Niterói", label: "Jardim Niterói" },
    { value: "Jardim Nizia", label: "Jardim Nizia" },
    { value: "Jardim Noêmia", label: "Jardim Noêmia" },
    { value: "Jardim Nordeste", label: "Jardim Nordeste" },
    { value: "Jardim Norma", label: "Jardim Norma" },
    { value: "Jardim Noronha", label: "Jardim Noronha" },
    { value: "Jardim Nossa Senhora Aparecida", label: "Jardim Nossa Senhora Aparecida" },
    { value: "Jardim Nossa Senhora do Carmo", label: "Jardim Nossa Senhora do Carmo" },
    { value: "Jardim Nosso Lar", label: "Jardim Nosso Lar" },
    { value: "Jardim Nova Conquista", label: "Jardim Nova Conquista" },
    { value: "Jardim Nova Germania", label: "Jardim Nova Germania" },
    { value: "Jardim Nova Guaianazes", label: "Jardim Nova Guaianazes" },
    { value: "Jardim Nova Harmonia", label: "Jardim Nova Harmonia" },
    { value: "Jardim Nova Tereza", label: "Jardim Nova Tereza" },
    { value: "Jardim Nova Vitória I", label: "Jardim Nova Vitória I" },
    { value: "Jardim Nova Vitória II", label: "Jardim Nova Vitória II" },
    { value: "Jardim Nove de Julho", label: "Jardim Nove de Julho" },
    { value: "Jardim Novo Carrão", label: "Jardim Novo Carrão" },
    { value: "Jardim Novo Horizonte", label: "Jardim Novo Horizonte" },
    { value: "Jardim Novo Jaú", label: "Jardim Novo Jaú" },
    { value: "Jardim Novo Lar", label: "Jardim Novo Lar" },
    { value: "Jardim Novo Mundo", label: "Jardim Novo Mundo" },
    { value: "Jardim Novo Pantanal", label: "Jardim Novo Pantanal" },
    { value: "Jardim Novo Parelheiros", label: "Jardim Novo Parelheiros" },
    { value: "Jardim Novo Santo Amaro", label: "Jardim Novo Santo Amaro" },
    { value: "Jardim Novo Taboão", label: "Jardim Novo Taboão" },
    { value: "Jardim Odete", label: "Jardim Odete" },
    { value: "Jardim Olinda", label: "Jardim Olinda" },
    { value: "Jardim Olympia", label: "Jardim Olympia" },
    { value: "Jardim Ondina", label: "Jardim Ondina" },
    { value: "Jardim Orbam", label: "Jardim Orbam" },
    { value: "Jardim Oriental", label: "Jardim Oriental" },
    { value: "Jardim Orly", label: "Jardim Orly" },
    { value: "Jardim Palmares", label: "Jardim Palmares" },
    { value: "Jardim Panorama", label: "Jardim Panorama" },
    { value: "Jardim Panorama D'oeste", label: "Jardim Panorama D’oeste" },
    { value: "Jardim Pantanal", label: "Jardim Pantanal" },
    { value: "Jardim Papai Noel", label: "Jardim Papai Noel" },
    { value: "Jardim Paquetá", label: "Jardim Paquetá" },
    { value: "Jardim Paraguaçu", label: "Jardim Paraguaçu" },
    { value: "Jardim Paraíso", label: "Jardim Paraíso" },
    { value: "Jardim Paraná", label: "Jardim Paraná" },
    { value: "Jardim Paris", label: "Jardim Paris" },
    { value: "Jardim Parque Morumbi", label: "Jardim Parque Morumbi" },
    { value: "Jardim Patente", label: "Jardim Patente" },
    { value: "Jardim Patente Novo", label: "Jardim Patente Novo" },
    { value: "Jardim Paulista", label: "Jardim Paulista" },
    { value: "Jardim Paulistano", label: "Jardim Paulistano" },
    { value: "Jardim Paulo Afonso", label: "Jardim Paulo Afonso" },
    { value: "Jardim Paulo Vi", label: "Jardim Paulo Vi" },
    { value: "Jardim Pedra Branca", label: "Jardim Pedra Branca" },
    { value: "Jardim Pedras", label: "Jardim Pedras" },
    { value: "Jardim Pedro José Nunes", label: "Jardim Pedro José Nunes" },
    { value: "Jardim Penha", label: "Jardim Penha" },
    { value: "Jardim Pereira Leite", label: "Jardim Pereira Leite" },
    { value: "Jardim Pereiras", label: "Jardim Pereiras" },
    { value: "Jardim Peri", label: "Jardim Peri" },
    { value: "Jardim Peri Novo", label: "Jardim Peri Novo" },
    { value: "Jardim Peri Peri", label: "Jardim Peri Peri" },
    { value: "Jardim Pérola I", label: "Jardim Pérola I" },
    { value: "Jardim Pérola II", label: "Jardim Pérola II" },
    { value: "Jardim Pérola III", label: "Jardim Pérola III" },
    { value: "Jardim Petrópolis", label: "Jardim Petrópolis" },
    { value: "Jardim Picolo", label: "Jardim Picolo" },
    { value: "Jardim Pinheiros", label: "Jardim Pinheiros" },
    { value: "Jardim Piqueroby", label: "Jardim Piqueroby" },
    { value: "Jardim Piracuama", label: "Jardim Piracuama" },
    { value: "Jardim Pirajussara", label: "Jardim Pirajussara" },
    { value: "Jardim Piratininga", label: "Jardim Piratininga" },
    { value: "Jardim Pirituba", label: "Jardim Pirituba" },
    { value: "Jardim Planalto", label: "Jardim Planalto" },
    { value: "Jardim Ponte Rasa", label: "Jardim Ponte Rasa" },
    { value: "Jardim Popular", label: "Jardim Popular" },
    { value: "Jardim Portal I E Ii", label: "Jardim Portal I E Ii" },
    { value: "Jardim Porteira Grande", label: "Jardim Porteira Grande" },
    { value: "Jardim Pouso Alegre", label: "Jardim Pouso Alegre" },
    { value: "Jardim Prainha", label: "Jardim Prainha" },
    { value: "Jardim Premiano", label: "Jardim Premiano" },
    { value: "Jardim Presidente", label: "Jardim Presidente" },
    { value: "Jardim Previdência", label: "Jardim Previdência" },
    { value: "Jardim Primavera", label: "Jardim Primavera" },
    { value: "Jardim Princesa", label: "Jardim Princesa" },
    { value: "Jardim Progresso", label: "Jardim Progresso" },
    { value: "Jardim Promissão", label: "Jardim Promissão" },
    { value: "Jardim Prudência", label: "Jardim Prudência" },
    { value: "Jardim Quarto Centenário", label: "Jardim Quarto Centenário" },
    { value: "Jardim Quisisana", label: "Jardim Quisisana" },
    { value: "Jardim Ramala", label: "Jardim Ramala" },
    { value: "Jardim Raposo Tavares", label: "Jardim Raposo Tavares" },
    { value: "Jardim Real", label: "Jardim Real" },
    { value: "Jardim Recanto do Sol", label: "Jardim Recanto do Sol" },
    { value: "Jardim Recanto Verde", label: "Jardim Recanto Verde" },
    { value: "Jardim Record", label: "Jardim Record" },
    { value: "Jardim Recreio", label: "Jardim Recreio" },
    { value: "Jardim Redenção", label: "Jardim Redenção" },
    { value: "Jardim Redil", label: "Jardim Redil" },
    { value: "Jardim Regina", label: "Jardim Regina" },
    { value: "Jardim Régis", label: "Jardim Régis" },
    { value: "Jardim Reimberg", label: "Jardim Reimberg" },
    { value: "Jardim Represa", label: "Jardim Represa" },
    { value: "Jardim República", label: "Jardim República" },
    { value: "Jardim Ribilene", label: "Jardim Ribilene" },
    { value: "Jardim Ricardo", label: "Jardim Ricardo" },
    { value: "Jardim Rincão", label: "Jardim Rincão" },
    { value: "Jardim Rio Bonito", label: "Jardim Rio Bonito" },
    { value: "Jardim Rio Douro", label: "Jardim Rio Douro" },
    { value: "Jardim Rio Pequeno", label: "Jardim Rio Pequeno" },
    { value: "Jardim Riviera", label: "Jardim Riviera" },
    { value: "Jardim Rizzo", label: "Jardim Rizzo" },
    { value: "Jardim Robru", label: "Jardim Robru" },
    { value: "Jardim Rodolfo Pirani", label: "Jardim Rodolfo Pirani" },
    { value: "Jardim Romano", label: "Jardim Romano" },
    { value: "Jardim Roni", label: "Jardim Roni" },
    { value: "Jardim Rosa Maria", label: "Jardim Rosa Maria" },
    { value: "Jardim Rosana", label: "Jardim Rosana" },
    { value: "Jardim Roschel", label: "Jardim Roschel" },
    { value: "Jardim Roseli", label: "Jardim Roseli" },
    { value: "Jardim Rosina", label: "Jardim Rosina" },
    { value: "Jardim Rosinha", label: "Jardim Rosinha" },
    { value: "Jardim Rossin", label: "Jardim Rossin" },
    { value: "Jardim Rubilene", label: "Jardim Rubilene" },
    { value: "Jardim Rubio", label: "Jardim Rubio" },
    { value: "Jardim Russo", label: "Jardim Russo" },
    { value: "Jardim Ruth", label: "Jardim Ruth" },
    { value: "Jardim S Kemel", label: "Jardim S Kemel" },
    { value: "Jardim Sabará", label: "Jardim Sabará" },
    { value: "Jardim Sabiá", label: "Jardim Sabiá" },
    { value: "Jardim Sabiá II", label: "Jardim Sabiá II" },
    { value: "Jardim Sagrado Coração", label: "Jardim Sagrado Coração" },
    { value: "Jardim Samambaia", label: "Jardim Samambaia" },
    { value: "Jardim Samara", label: "Jardim Samara" },
    { value: "Jardim Sandra", label: "Jardim Sandra" },
    { value: "Jardim Sandra Maria", label: "Jardim Sandra Maria" },
    { value: "Jardim Santa Adélia", label: "Jardim Santa Adélia" },
    { value: "Jardim Santa Bárbara", label: "Jardim Santa Bárbara" },
    { value: "Jardim Santa Cruz", label: "Jardim Santa Cruz" },
    { value: "Jardim Santa Edwiges", label: "Jardim Santa Edwiges" },
    { value: "Jardim Santa Efigênia", label: "Jardim Santa Efigênia" },
    { value: "Jardim Santa Emília", label: "Jardim Santa Emília" },
    { value: "Jardim Santa Etelvina", label: "Jardim Santa Etelvina" },
    { value: "Jardim Santa Fé", label: "Jardim Santa Fé" },
    { value: "Jardim Santa Filomena", label: "Jardim Santa Filomena" },
    { value: "Jardim Santa Francisca Cabrini", label: "Jardim Santa Francisca Cabrini" },
    { value: "Jardim Santa Gertrudes", label: "Jardim Santa Gertrudes" },
    { value: "Jardim Santa Helena", label: "Jardim Santa Helena" },
    { value: "Jardim Santa Ines", label: "Jardim Santa Ines" },
    { value: "Jardim Santa Josefina", label: "Jardim Santa Josefina" },
    { value: "Jardim Santa Lucrécia", label: "Jardim Santa Lucrécia" },
    { value: "Jardim Santa Margarida", label: "Jardim Santa Margarida" },
    { value: "Jardim Santa Maria", label: "Jardim Santa Maria" },
    { value: "Jardim Santa Mônica", label: "Jardim Santa Mônica" },
    { value: "Jardim Santa Rita", label: "Jardim Santa Rita" },
    { value: "Jardim Santa Tereza", label: "Jardim Santa Tereza" },
    { value: "Jardim Santa Terezinha", label: "Jardim Santa Terezinha" },
    { value: "Jardim Santa Zélia", label: "Jardim Santa Zélia" },
    { value: "Jardim Santo Alberto", label: "Jardim Santo Alberto" },
    { value: "Jardim Santo Amaro", label: "Jardim Santo Amaro" },
    { value: "Jardim Santo André", label: "Jardim Santo André" },
    { value: "Jardim Santo Antoninho", label: "Jardim Santo Antoninho" },
    { value: "Jardim Santo Antônio", label: "Jardim Santo Antônio" },
    { value: "Jardim Santo Elias", label: "Jardim Santo Elias" },
    { value: "Jardim Santo Onofre", label: "Jardim Santo Onofre" },
    { value: "Jardim Santos Dumont", label: "Jardim Santos Dumont" },
    { value: "Jardim São Benedito", label: "Jardim São Benedito" },
    { value: "Jardim São Bento", label: "Jardim São Bento" },
    { value: "Jardim São Bento Novo", label: "Jardim São Bento Novo" },
    { value: "Jardim São Bernardo", label: "Jardim São Bernardo" },
    { value: "Jardim São Carlos", label: "Jardim São Carlos" },
    { value: "Jardim São Cristóvão", label: "Jardim São Cristóvão" },
    { value: "Jardim São Francisco", label: "Jardim São Francisco" },
    { value: "Jardim São Francisco de Assis", label: "Jardim São Francisco de Assis" },
    { value: "Jardim São Gabriel", label: "Jardim São Gabriel" },
    { value: "Jardim São Gilberto", label: "Jardim São Gilberto" },
    { value: "Jardim São Gonçalo", label: "Jardim São Gonçalo" },
    { value: "Jardim São Januário", label: "Jardim São Januário" },
    { value: "Jardim São João", label: "Jardim São João" },
    { value: "Jardim São Joaquim", label: "Jardim São Joaquim" },
    { value: "Jardim São Jorge", label: "Jardim São Jorge" },
    { value: "Jardim São José", label: "Jardim São José" },
    { value: "Jardim São Judas Tadeu", label: "Jardim São Judas Tadeu" },
    { value: "Jardim São Luís", label: "Jardim São Luís" },
    { value: "Jardim São Manoel", label: "Jardim São Manoel" },
    { value: "Jardim São Martinho", label: "Jardim São Martinho" },
    { value: "Jardim São Mateus", label: "Jardim São Mateus" },
    { value: "Jardim São Miguel", label: "Jardim São Miguel" },
    { value: "Jardim São Nicolau", label: "Jardim São Nicolau" },
    { value: "Jardim São Paulo", label: "Jardim São Paulo" },
    { value: "Jardim São Pedro", label: "Jardim São Pedro" },
    { value: "Jardim São Rafael", label: "Jardim São Rafael" },
    { value: "Jardim São Remo", label: "Jardim São Remo" },
    { value: "Jardim São Ricardo", label: "Jardim São Ricardo" },
    { value: "Jardim São Roberto", label: "Jardim São Roberto" },
    { value: "Jardim São Roque", label: "Jardim São Roque" },
    { value: "Jardim São Saverio", label: "Jardim São Saverio" },
    { value: "Jardim São Sebastião", label: "Jardim São Sebastião" },
    { value: "Jardim São Silvestre", label: "Jardim São Silvestre" },
    { value: "Jardim São Vicente", label: "Jardim São Vicente" },
    { value: "Jardim São Vítor", label: "Jardim São Vítor" },
    { value: "Jardim Sapopemba", label: "Jardim Sapopemba" },
    { value: "Jardim Sarah", label: "Jardim Sarah" },
    { value: "Jardim Satélite", label: "Jardim Satélite" },
    { value: "Jardim Seckler", label: "Jardim Seckler" },
    { value: "Jardim Selma", label: "Jardim Selma" },
    { value: "Jardim Senice", label: "Jardim Senice" },
    { value: "Jardim Sertãozinho", label: "Jardim Sertãozinho" },
    { value: "Jardim Shangrilá", label: "Jardim Shangrilá" },
    { value: "Jardim Silva Teles", label: "Jardim Silva Teles" },
    { value: "Jardim Silveira", label: "Jardim Silveira" },
    { value: "Jardim Sílvia", label: "Jardim Sílvia" },
    { value: "Jardim Sipramar", label: "Jardim Sipramar" },
    { value: "Jardim Soares", label: "Jardim Soares" },
    { value: "Jardim Solange", label: "Jardim Solange" },
    { value: "Jardim Somara", label: "Jardim Somara" },
    { value: "Jardim Sônia", label: "Jardim Sônia" },
    { value: "Jardim Sônia Ingá", label: "Jardim Sônia Ingá" },
    { value: "Jardim Sônia Regina", label: "Jardim Sônia Regina" },
    { value: "Jardim Soraia", label: "Jardim Soraia" },
    { value: "Jardim Souza", label: "Jardim Souza" },
    { value: "Jardim Sul São Paulo", label: "Jardim Sul São Paulo" },
    { value: "Jardim Suzana", label: "Jardim Suzana" },
    { value: "Jardim Sydney", label: "Jardim Sydney" },
    { value: "Jardim Taboão", label: "Jardim Taboão" },
    { value: "Jardim Taipas", label: "Jardim Taipas" },
    { value: "Jardim Tamoio", label: "Jardim Tamoio" },
    { value: "Jardim Tanay", label: "Jardim Tanay" },
    { value: "Jardim Tango", label: "Jardim Tango" },
    { value: "Jardim Tapera", label: "Jardim Tapera" },
    { value: "Jardim Taquaral", label: "Jardim Taquaral" },
    { value: "Jardim Tenani", label: "Jardim Tenani" },
    { value: "Jardim Teresa", label: "Jardim Teresa" },
    { value: "Jardim Têxtil", label: "Jardim Têxtil" },
    { value: "Jardim Thealia", label: "Jardim Thealia" },
    { value: "Jardim Thomaz", label: "Jardim Thomaz" },
    { value: "Jardim Tietê", label: "Jardim Tietê" },
    { value: "Jardim Tiro Alto Pombo", label: "Jardim Tiro Alto Pombo" },
    { value: "Jardim Toca", label: "Jardim Toca" },
    { value: "Jardim Tremembé", label: "Jardim Tremembé" },
    { value: "Jardim Três Corações", label: "Jardim Três Corações" },
    { value: "Jardim Três Marias", label: "Jardim Três Marias" },
    { value: "Jardim Triana", label: "Jardim Triana" },
    { value: "Jardim Tropical", label: "Jardim Tropical" },
    { value: "Jardim Trussardi", label: "Jardim Trussardi" },
    { value: "Jardim Tua", label: "Jardim Tua" },
    { value: "Jardim Tupã", label: "Jardim Tupã" },
    { value: "Jardim Tupi", label: "Jardim Tupi" },
    { value: "Jardim Turquesa", label: "Jardim Turquesa" },
    { value: "Jardim Uberaba", label: "Jardim Uberaba" },
    { value: "Jardim Ubirajara", label: "Jardim Ubirajara" },
    { value: "Jardim Uirapuru", label: "Jardim Uirapuru" },
    { value: "Jardim Umarizal", label: "Jardim Umarizal" },
    { value: "Jardim Umuarama", label: "Jardim Umuarama" },
    { value: "Jardim União", label: "Jardim União" },
    { value: "Jardim Uniserve", label: "Jardim Uniserve" },
    { value: "Jardim Universidade Pinheiros", label: "Jardim Universidade Pinheiros" },
    { value: "Jardim Vale das Virtudes", label: "Jardim Vale das Virtudes" },
    { value: "Jardim Valparaíso", label: "Jardim Valparaíso" },
    { value: "Jardim Valquíria", label: "Jardim Valquíria" },
    { value: "Jardim Varginha", label: "Jardim Varginha" },
    { value: "Jardim Vaz de Lima", label: "Jardim Vaz de Lima" },
    { value: "Jardim Vazani", label: "Jardim Vazani" },
    { value: "Jardim Vera Cruz", label: "Jardim Vera Cruz" },
    { value: "Jardim Vergueiro", label: "Jardim Vergueiro" },
    { value: "Jardim Veronia", label: "Jardim Veronia" },
    { value: "Jardim Viana", label: "Jardim Viana" },
    { value: "Jardim Vieira", label: "Jardim Vieira" },
    { value: "Jardim Vila Carrão", label: "Jardim Vila Carrão" },
    { value: "Jardim Vila Formosa", label: "Jardim Vila Formosa" },
    { value: "Jardim Vila Mariana", label: "Jardim Vila Mariana" },
    { value: "Jardim Vila Rica", label: "Jardim Vila Rica" },
    { value: "Jardim Vilas Boas", label: "Jardim Vilas Boas" },
    { value: "Jardim Vilma", label: "Jardim Vilma" },
    { value: "Jardim Virgínia", label: "Jardim Virgínia" },
    { value: "Jardim Virgínia Bianca", label: "Jardim Virgínia Bianca" },
    { value: "Jardim Vista Alegre", label: "Jardim Vista Alegre" },
    { value: "Jardim Vista Linda", label: "Jardim Vista Linda" },
    { value: "Jardim Vitória", label: "Jardim Vitória" },
    { value: "Jardim Vitória Régia", label: "Jardim Vitória Régia" },
    { value: "Jardim Vivan", label: "Jardim Vivan" },
    { value: "Jardim Wanda", label: "Jardim Wanda" },
    { value: "Jardim Yara", label: "Jardim Yara" },
    { value: "Jardim Zaira", label: "Jardim Zaira" },
    { value: "Jardim Zilda", label: "Jardim Zilda" },
    { value: "Jardins", label: "Jardins" },
    { value: "Jardins Recanto das Rosas", label: "Jardins Recanto das Rosas" },
    { value: "Jordanópolis", label: "Jordanópolis" },
    { value: "Jurubatuba", label: "Jurubatuba" },
    { value: "Lajeado", label: "Lajeado" },
    { value: "Lapa", label: "Lapa" },
    { value: "Lapa de Baixo", label: "Lapa de Baixo" },
    { value: "Lar São Paulo", label: "Lar São Paulo" },
    { value: "Lauzane Paulista", label: "Lauzane Paulista" },
    { value: "Liberdade", label: "Liberdade" },
    { value: "Limão", label: "Limão" },
    { value: "Limoeiro", label: "Limoeiro" },
    { value: "Loteamento City Jaraguá", label: "Loteamento City Jaraguá" },
    { value: "Luz", label: "Luz" },
    { value: "Mandaqui", label: "Mandaqui" },
    { value: "Maranhão", label: "Maranhão" },
    { value: "Marsilac", label: "Marsilac" },
    { value: "Mata Fria", label: "Mata Fria" },
    { value: "Miami Paulista", label: "Miami Paulista" },
    { value: "Mirandópolis", label: "Mirandópolis" },
    { value: "Moema", label: "Moema" },
    { value: "Moinho Velho", label: "Moinho Velho" },
    { value: "Mooca", label: "Mooca" },
    { value: "Morada do Sol", label: "Morada do Sol" },
    { value: "Morro do Índio", label: "Morro do Índio" },
    { value: "Morro dos Ingleses", label: "Morro dos Ingleses" },
    { value: "Morro Grande", label: "Morro Grande" },
    { value: "Morumbi", label: "Morumbi" },
    { value: "Nossa Senhora do O", label: "Nossa Senhora do O" },
    { value: "Nova Piraju", label: "Nova Piraju" },
    { value: "Núcleo do Engordador", label: "Núcleo do Engordador" },
    { value: "Núcleo Lageado", label: "Núcleo Lageado" },
    { value: "Pacaembu", label: "Pacaembu" },
    { value: "Paineiras do Morumbi", label: "Paineiras do Morumbi" },
    { value: "Palanque", label: "Palanque" },
    { value: "Parada", label: "Parada" },
    { value: "Parada Inglesa", label: "Parada Inglesa" },
    { value: "Parada Xv de Novembro", label: "Parada Xv de Novembro" },
    { value: "Parada Xv Novembro", label: "Parada Xv Novembro" },
    { value: "Paraíso", label: "Paraíso" },
    { value: "Paraíso do Morumbi", label: "Paraíso do Morumbi" },
    { value: "Paraisópolis", label: "Paraisópolis" },
    { value: "Parelheiros", label: "Parelheiros" },
    { value: "Pari", label: "Pari" },
    { value: "Parque Alto do Rio Bonito", label: "Parque Alto do Rio Bonito" },
    { value: "Parque Alves de Lima", label: "Parque Alves de Lima" },
    { value: "Parque Amazonas", label: "Parque Amazonas" },
    { value: "Parque Amélia", label: "Parque Amélia" },
    { value: "Parque América", label: "Parque América" },
    { value: "Parque Anhanguera", label: "Parque Anhanguera" },
    { value: "Parque Anhembi", label: "Parque Anhembi" },
    { value: "Parque Arariba", label: "Parque Arariba" },
    { value: "Parque Artur Alvim", label: "Parque Artur Alvim" },
    { value: "Parque Atlântico", label: "Parque Atlântico" },
    { value: "Parque Bairro Morumbi", label: "Parque Bairro Morumbi" },
    { value: "Parque Belém", label: "Parque Belém" },
    { value: "Parque Boa Esperança", label: "Parque Boa Esperança" },
    { value: "Parque Bologne", label: "Parque Bologne" },
    { value: "Parque Boturussu", label: "Parque Boturussu" },
    { value: "Parque Brasil", label: "Parque Brasil" },
    { value: "Parque Bristol", label: "Parque Bristol" },
    { value: "Parque Casa de Pedra", label: "Parque Casa de Pedra" },
    { value: "Parque Central", label: "Parque Central" },
    { value: "Parque Cisper", label: "Parque Cisper" },
    { value: "Parque Cocaia", label: "Parque Cocaia" },
    { value: "Parque Colonial", label: "Parque Colonial" },
    { value: "Parque Continental", label: "Parque Continental" },
    { value: "Parque Cristina", label: "Parque Cristina" },
    { value: "Parque Cruzeiro do Sul", label: "Parque Cruzeiro do Sul" },
    { value: "Parque da Lapa", label: "Parque da Lapa" },
    { value: "Parque da Mooca", label: "Parque da Mooca" },
    { value: "Parque da Vila Prudente", label: "Parque da Vila Prudente" },
    { value: "Parque das Árvores", label: "Parque das Árvores" },
    { value: "Parque das Cerejeiras", label: "Parque das Cerejeiras" },
    { value: "Parque das Flores", label: "Parque das Flores" },
    { value: "Parque das Paineiras", label: "Parque das Paineiras" },
    { value: "Parque Deizy", label: "Parque Deizy" },
    { value: "Parque do Carmo", label: "Parque do Carmo" },
    { value: "Parque do Castelo", label: "Parque do Castelo" },
    { value: "Parque do Lago", label: "Parque do Lago" },
    { value: "Parque do Morumbi", label: "Parque do Morumbi" },
    { value: "Parque do Otero", label: "Parque do Otero" },
    { value: "Parque do Terceiro Lago", label: "Parque do Terceiro Lago" },
    { value: "Parque Dom João Neri", label: "Parque Dom João Neri" },
    { value: "Parque Doroteia", label: "Parque Doroteia" },
    { value: "Parque dos Bancários", label: "Parque dos Bancários" },
    { value: "Parque dos Príncipes", label: "Parque dos Príncipes" },
    { value: "Parque Edu Chaves", label: "Parque Edu Chaves" },
    { value: "Parque Esmeralda", label: "Parque Esmeralda" },
    { value: "Parque Esperança", label: "Parque Esperança" },
    { value: "Parque Fernanda", label: "Parque Fernanda" },
    { value: "Parque Flamengo", label: "Parque Flamengo" },
    { value: "Parque Florestal", label: "Parque Florestal" },
    { value: "Parque Fongaro", label: "Parque Fongaro" },
    { value: "Parque Grajaú", label: "Parque Grajaú" },
    { value: "Parque Guaianazes", label: "Parque Guaianazes" },
    { value: "Parque Guarani", label: "Parque Guarani" },
    { value: "Parque Ibirapuera", label: "Parque Ibirapuera" },
    { value: "Parque Imperial", label: "Parque Imperial" },
    { value: "Parque Independência", label: "Parque Independência" },
    { value: "Parque Industrial", label: "Parque Industrial" },
    { value: "Parque Industrial Tomás Edson", label: "Parque Industrial Tomás Edson" },
    { value: "Parque Ipe", label: "Parque Ipe" },
    { value: "Parque Itaberaba", label: "Parque Itaberaba" },
    { value: "Parque Jabaquara", label: "Parque Jabaquara" },
    { value: "Parque Lagoa Rica", label: "Parque Lagoa Rica" },
    { value: "Parque Lígia", label: "Parque Lígia" },
    { value: "Parque Luís Mucciolo", label: "Parque Luís Mucciolo" },
    { value: "Parque Malagoli", label: "Parque Malagoli" },
    { value: "Parque Mandaqui", label: "Parque Mandaqui" },
    { value: "Parque Mandi", label: "Parque Mandi" },
    { value: "Parque Maria Alice", label: "Parque Maria Alice" },
    { value: "Parque Maria Domitila", label: "Parque Maria Domitila" },
    { value: "Parque Maria Fernandes", label: "Parque Maria Fernandes" },
    { value: "Parque Maria Helena", label: "Parque Maria Helena" },
    { value: "Parque Maria Luíza", label: "Parque Maria Luíza" },
    { value: "Parque Monteiro Soares", label: "Parque Monteiro Soares" },
    { value: "Parque Morumbi", label: "Parque Morumbi" },
    { value: "Parque Munhoz", label: "Parque Munhoz" },
    { value: "Parque Nações Unidas", label: "Parque Nações Unidas" },
    { value: "Parque Novo Grajaú", label: "Parque Novo Grajaú" },
    { value: "Parque Novo Lar", label: "Parque Novo Lar" },
    { value: "Parque Novo Mundo", label: "Parque Novo Mundo" },
    { value: "Parque Novo Santo Amaro", label: "Parque Novo Santo Amaro" },
    { value: "Parque Paineiras", label: "Parque Paineiras" },
    { value: "Parque Palmas do Tremembé", label: "Parque Palmas do Tremembé" },
    { value: "Parque Panamericano", label: "Parque Panamericano" },
    { value: "Parque Paulistano", label: "Parque Paulistano" },
    { value: "Parque Penha", label: "Parque Penha" },
    { value: "Parque Pereira", label: "Parque Pereira" },
    { value: "Parque Peruche", label: "Parque Peruche" },
    { value: "Parque Planalto", label: "Parque Planalto" },
    { value: "Parque Primavera", label: "Parque Primavera" },
    { value: "Parque Ramos Freitas", label: "Parque Ramos Freitas" },
    { value: "Parque Rebouças", label: "Parque Rebouças" },
    { value: "Parque Recreio", label: "Parque Recreio" },
    { value: "Parque Regina", label: "Parque Regina" },
    { value: "Parque Residencial Cocaia", label: "Parque Residencial Cocaia" },
    { value: "Parque Residencial D'abril", label: "Parque Residencial D’abril" },
    { value: "Parque Residencial da Lapa", label: "Parque Residencial da Lapa" },
    { value: "Parque Residencial dos Lagos", label: "Parque Residencial dos Lagos" },
    { value: "Parque Residencial Júlia", label: "Parque Residencial Júlia" },
    { value: "Parque Residencial Oratório", label: "Parque Residencial Oratório" },
    { value: "Parque Rodrigues Alves", label: "Parque Rodrigues Alves" },
    { value: "Parque Santa Amélia", label: "Parque Santa Amélia" },
    { value: "Parque Santa Bárbara", label: "Parque Santa Bárbara" },
    { value: "Parque Santa Cecília", label: "Parque Santa Cecília" },
    { value: "Parque Santa Madalena", label: "Parque Santa Madalena" },
    { value: "Parque Santa Rita", label: "Parque Santa Rita" },
    { value: "Parque Santana", label: "Parque Santana" },
    { value: "Parque Santo Amaro", label: "Parque Santo Amaro" },
    { value: "Parque Santo Antônio", label: "Parque Santo Antônio" },
    { value: "Parque Santo Eduardo", label: "Parque Santo Eduardo" },
    { value: "Parque São Domingos", label: "Parque São Domingos" },
    { value: "Parque São Jorge", label: "Parque São Jorge" },
    { value: "Parque São José", label: "Parque São José" },
    { value: "Parque São Lourenço", label: "Parque São Lourenço" },
    { value: "Parque São Lucas", label: "Parque São Lucas" },
    { value: "Parque São Luís", label: "Parque São Luís" },
    { value: "Parque São Miguel", label: "Parque São Miguel" },
    { value: "Parque São Paulo", label: "Parque São Paulo" },
    { value: "Parque São Rafael", label: "Parque São Rafael" },
    { value: "Parque Savoy City", label: "Parque Savoy City" },
    { value: "Parque Sevilha", label: "Parque Sevilha" },
    { value: "Parque Sônia", label: "Parque Sônia" },
    { value: "Parque Taipas", label: "Parque Taipas" },
    { value: "Parque Tamari", label: "Parque Tamari" },
    { value: "Parque Tietê", label: "Parque Tietê" },
    { value: "Parque Tomás Saraiva", label: "Parque Tomás Saraiva" },
    { value: "Parque Universitário Espírita", label: "Parque Universitário Espírita" },
    { value: "Parque Veloso", label: "Parque Veloso" },
    { value: "Parque Vila Maria", label: "Parque Vila Maria" },
    { value: "Parque Vitória", label: "Parque Vitória" },
    { value: "Pedreira", label: "Pedreira" },
    { value: "Penha de Franca", label: "Penha de Franca" },
    { value: "Penha França", label: "Penha França" },
    { value: "Perdizes", label: "Perdizes" },
    { value: "Perus", label: "Perus" },
    { value: "Pinheiros", label: "Pinheiros" },
    { value: "Piqueri", label: "Piqueri" },
    { value: "Pirajussara", label: "Pirajussara" },
    { value: "Pirituba", label: "Pirituba" },
    { value: "Planalto Paulista", label: "Planalto Paulista" },
    { value: "Ponte Pequena", label: "Ponte Pequena" },
    { value: "Ponte Rasa", label: "Ponte Rasa" },
    { value: "Praia Azul", label: "Praia Azul" },
    { value: "Praia da Lagoa", label: "Praia da Lagoa" },
    { value: "Praia do Leblon", label: "Praia do Leblon" },
    { value: "Praia Paulistinha", label: "Praia Paulistinha" },
    { value: "Praias Paulistanas", label: "Praias Paulistanas" },
    { value: "Promorar Vila Maria", label: "Promorar Vila Maria" },
    { value: "Protendit", label: "Protendit" },
    { value: "Quarta Parada", label: "Quarta Parada" },
    { value: "Quinta da Paineira", label: "Quinta da Paineira" },
    { value: "Raposo Tavares", label: "Raposo Tavares" },
    { value: "Real Parque", label: "Real Parque" },
    { value: "Recanto Alegre", label: "Recanto Alegre" },
    { value: "Recanto Ana Maria", label: "Recanto Ana Maria" },
    { value: "Recanto Campo Belo", label: "Recanto Campo Belo" },
    { value: "Recanto dos Sonhos", label: "Recanto dos Sonhos" },
    { value: "Recanto Marisa", label: "Recanto Marisa" },
    { value: "Recanto Monte Alegre", label: "Recanto Monte Alegre" },
    { value: "Recanto Paraíso", label: "Recanto Paraíso" },
    { value: "Recanto Santo Antônio", label: "Recanto Santo Antônio" },
    { value: "Recanto Verde do Sol", label: "Recanto Verde do Sol" },
    { value: "República", label: "República" },
    { value: "Residencial Morumbi", label: "Residencial Morumbi" },
    { value: "Residencial Sol Nascente", label: "Residencial Sol Nascente" },
    { value: "Residencial Vilela", label: "Residencial Vilela" },
    { value: "Retiro Morumbi", label: "Retiro Morumbi" },
    { value: "Rio Bonito", label: "Rio Bonito" },
    { value: "Rio Pequeno", label: "Rio Pequeno" },
    { value: "Riviera Paulista", label: "Riviera Paulista" },
    { value: "Rolinopolis", label: "Rolinopolis" },
    { value: "Sacomã", label: "Sacomã" },
    { value: "Santa Amélia", label: "Santa Amélia" },
    { value: "Santa Cecília", label: "Santa Cecília" },
    { value: "Santa Efigênia", label: "Santa Efigênia" },
    { value: "Santa Etelvina", label: "Santa Etelvina" },
    { value: "Santa Ifigenia", label: "Santa Ifigenia" },
    { value: "Santa Teresinha", label: "Santa Teresinha" },
    { value: "Santana", label: "Santana" },
    { value: "Santo Amaro", label: "Santo Amaro" },
    { value: "São João Clímaco", label: "São João Clímaco" },
    { value: "São Judas", label: "São Judas" },
    { value: "São Mateus", label: "São Mateus" },
    { value: "São Miguel Paulista", label: "São Miguel Paulista" },
    { value: "São Salvador", label: "São Salvador" },
    { value: "Sapopemba", label: "Sapopemba" },
    { value: "Saúde", label: "Saúde" },
    { value: "Sé", label: "Sé" },
    { value: "Sete Praias", label: "Sete Praias" },
    { value: "Siciliano", label: "Siciliano" },
    { value: "Sítio Areião", label: "Sítio Areião" },
    { value: "Sítio Barrocada", label: "Sítio Barrocada" },
    { value: "Sítio Botuquara", label: "Sítio Botuquara" },
    { value: "Sítio Caraguatá", label: "Sítio Caraguatá" },
    { value: "Sítio da Figueira", label: "Sítio da Figueira" },
    { value: "Sítio do Mandaqui", label: "Sítio do Mandaqui" },
    { value: "Sítio do Morro", label: "Sítio do Morro" },
    { value: "Sítio do Piqueri", label: "Sítio do Piqueri" },
    { value: "Sítio Itaberaba I", label: "Sítio Itaberaba I" },
    { value: "Sítio Itaberaba II", label: "Sítio Itaberaba II" },
    { value: "Sítio Morro Grande", label: "Sítio Morro Grande" },
    { value: "Sítio Pinheirinho", label: "Sítio Pinheirinho" },
    { value: "Sítio Represa", label: "Sítio Represa" },
    { value: "Socorro", label: "Socorro" },
    { value: "Sumaré", label: "Sumaré" },
    { value: "Sumarezinho", label: "Sumarezinho" },
    { value: "Super Quadra Morumbi", label: "Super Quadra Morumbi" },
    { value: "Tatuapé", label: "Tatuapé" },
    { value: "Terceira Divisão", label: "Terceira Divisão" },
    { value: "Terceira Divisão de Interlagos", label: "Terceira Divisão de Interlagos" },
    { value: "Tremembé", label: "Tremembé" },
    { value: "Três Cruzes", label: "Três Cruzes" },
    { value: "Tucuruvi", label: "Tucuruvi" },
    { value: "Umarizal", label: "Umarizal" },
    { value: "União de Vila Nova", label: "União de Vila Nova" },
    { value: "Usina Piratininga", label: "Usina Piratininga" },
    { value: "Valo Velho", label: "Valo Velho" },
    { value: "Várzea Barra Funda", label: "Várzea Barra Funda" },
    { value: "Várzea da Barra Funda", label: "Várzea da Barra Funda" },
    { value: "Várzea de Baixo", label: "Várzea de Baixo" },
    { value: "Veleiros", label: "Veleiros" },
    { value: "Vila Abc", label: "Vila Abc" },
    { value: "Vila Acre", label: "Vila Acre" },
    { value: "Vila Adalgisa", label: "Vila Adalgisa" },
    { value: "Vila Água Funda", label: "Vila Água Funda" },
    { value: "Vila Agueda", label: "Vila Agueda" },
    { value: "Vila Aimoré", label: "Vila Aimoré" },
    { value: "Vila Alabama", label: "Vila Alabama" },
    { value: "Vila Alba", label: "Vila Alba" },
    { value: "Vila Albano", label: "Vila Albano" },
    { value: "Vila Albertina", label: "Vila Albertina" },
    { value: "Vila Alexandria", label: "Vila Alexandria" },
    { value: "Vila Almeida", label: "Vila Almeida" },
    { value: "Vila Alpina", label: "Vila Alpina" },
    { value: "Vila Alzira", label: "Vila Alzira" },
    { value: "Vila Amália", label: "Vila Amália" },
    { value: "Vila Amélia", label: "Vila Amélia" },
    { value: "Vila América", label: "Vila América" },
    { value: "Vila Americana", label: "Vila Americana" },
    { value: "Vila Ana Rosa", label: "Vila Ana Rosa" },
    { value: "Vila Anadir", label: "Vila Anadir" },
    { value: "Vila Anália", label: "Vila Anália" },
    { value: "Vila Anastácio", label: "Vila Anastácio" },
    { value: "Vila Andes", label: "Vila Andes" },
    { value: "Vila Andrade", label: "Vila Andrade" },
    { value: "Vila Anglo Brasileira", label: "Vila Anglo Brasileira" },
    { value: "Vila Anhanguera", label: "Vila Anhanguera" },
    { value: "Vila Antonieta", label: "Vila Antonieta" },
    { value: "Vila Antonina", label: "Vila Antonina" },
    { value: "Vila Antônio", label: "Vila Antônio" },
    { value: "Vila Antônio dos Santos", label: "Vila Antônio dos Santos" },
    { value: "Vila Aparecida", label: "Vila Aparecida" },
    { value: "Vila Aparecida Ivone", label: "Vila Aparecida Ivone" },
    { value: "Vila Araguaia", label: "Vila Araguaia" },
    { value: "Vila Arapuã", label: "Vila Arapuã" },
    { value: "Vila Arcádia", label: "Vila Arcádia" },
    { value: "Vila Aricanduva", label: "Vila Aricanduva" },
    { value: "Vila Arriete", label: "Vila Arriete" },
    { value: "Vila Arruda", label: "Vila Arruda" },
    { value: "Vila Augusto", label: "Vila Augusto" },
    { value: "Vila Áurea", label: "Vila Áurea" },
    { value: "Vila Aurora", label: "Vila Aurora" },
    { value: "Vila Ayrosa", label: "Vila Ayrosa" },
    { value: "Vila Azevedo", label: "Vila Azevedo" },
    { value: "Vila Babilônia", label: "Vila Babilônia" },
    { value: "Vila Baby", label: "Vila Baby" },
    { value: "Vila Bancária", label: "Vila Bancária" },
    { value: "Vila Bancaria Munhoz", label: "Vila Bancaria Munhoz" },
    { value: "Vila Bandeirantes", label: "Vila Bandeirantes" },
    { value: "Vila Barbosa", label: "Vila Barbosa" },
    { value: "Vila Barreto", label: "Vila Barreto" },
    { value: "Vila Baruel", label: "Vila Baruel" },
    { value: "Vila Basileia", label: "Vila Basileia" },
    { value: "Vila Bauab", label: "Vila Bauab" },
    { value: "Vila Beatriz", label: "Vila Beatriz" },
    { value: "Vila Bela", label: "Vila Bela" },
    { value: "Vila Bela Aliança", label: "Vila Bela Aliança" },
    { value: "Vila Bela do Sapopemba", label: "Vila Bela do Sapopemba" },
    { value: "Vila Bela Vista", label: "Vila Bela Vista" },
    { value: "Vila Belo Horizonte", label: "Vila Belo Horizonte" },
    { value: "Vila Bertioga", label: "Vila Bertioga" },
    { value: "Vila Bianca", label: "Vila Bianca" },
    { value: "Vila Boacava", label: "Vila Boacava" },
    { value: "Vila Bom Jardim", label: "Vila Bom Jardim" },
    { value: "Vila Bonilha", label: "Vila Bonilha" },
    { value: "Vila Bonilha Nova", label: "Vila Bonilha Nova" },
    { value: "Vila Borges", label: "Vila Borges" },
    { value: "Vila Bozzini", label: "Vila Bozzini" },
    { value: "Vila Brasil", label: "Vila Brasil" },
    { value: "Vila Brasilândia", label: "Vila Brasilândia" },
    { value: "Vila Brasília", label: "Vila Brasília" },
    { value: "Vila Brasilina", label: "Vila Brasilina" },
    { value: "Vila Brasílio Machado", label: "Vila Brasílio Machado" },
    { value: "Vila Bruna", label: "Vila Bruna" },
    { value: "Vila Buarque", label: "Vila Buarque" },
    { value: "Vila Buenos Aires", label: "Vila Buenos Aires" },
    { value: "Vila Butantã", label: "Vila Butantã" },
    { value: "Vila Cachoeira", label: "Vila Cachoeira" },
    { value: "Vila Caiçara", label: "Vila Caiçara" },
    { value: "Vila Caiuba", label: "Vila Caiuba" },
    { value: "Vila Caju", label: "Vila Caju" },
    { value: "Vila Califórnia", label: "Vila Califórnia" },
    { value: "Vila Calu", label: "Vila Calu" },
    { value: "Vila Campanela", label: "Vila Campanela" },
    { value: "Vila Campestre", label: "Vila Campestre" },
    { value: "Vila Campo Grande", label: "Vila Campo Grande" },
    { value: "Vila Canero", label: "Vila Canero" },
    { value: "Vila Capela", label: "Vila Capela" },
    { value: "Vila Caraguatá", label: "Vila Caraguatá" },
    { value: "Vila Carbone", label: "Vila Carbone" },
    { value: "Vila Cardoso Franco", label: "Vila Cardoso Franco" },
    { value: "Vila Carioca", label: "Vila Carioca" },
    { value: "Vila Carlos de Campos", label: "Vila Carlos de Campos" },
    { value: "Vila Carmem", label: "Vila Carmem" },
    { value: "Vila Carmosina", label: "Vila Carmosina" },
    { value: "Vila Carolina", label: "Vila Carolina" },
    { value: "Vila Carrão", label: "Vila Carrão" },
    { value: "Vila Castelo", label: "Vila Castelo" },
    { value: "Vila Catupia", label: "Vila Catupia" },
    { value: "Vila Cavaton", label: "Vila Cavaton" },
    { value: "Vila Caxambu", label: "Vila Caxambu" },
    { value: "Vila Celeste", label: "Vila Celeste" },
    { value: "Vila Centenário", label: "Vila Centenário" },
    { value: "Vila Central", label: "Vila Central" },
    { value: "Vila Chabilândia", label: "Vila Chabilândia" },
    { value: "Vila Chalot", label: "Vila Chalot" },
    { value: "Vila Charlote", label: "Vila Charlote" },
    { value: "Vila Chavantes", label: "Vila Chavantes" },
    { value: "Vila Chica Luisa", label: "Vila Chica Luisa" },
    { value: "Vila Chuca", label: "Vila Chuca" },
    { value: "Vila Cisper", label: "Vila Cisper" },
    { value: "Vila Clara", label: "Vila Clara" },
    { value: "Vila Clarice", label: "Vila Clarice" },
    { value: "Vila Cláudia", label: "Vila Cláudia" },
    { value: "Vila Clementino", label: "Vila Clementino" },
    { value: "Vila Cleonice", label: "Vila Cleonice" },
    { value: "Vila Comercial", label: "Vila Comercial" },
    { value: "Vila Conceição", label: "Vila Conceição" },
    { value: "Vila Condomínio do Pinhal", label: "Vila Condomínio do Pinhal" },
    { value: "Vila Condomínio Pinhal", label: "Vila Condomínio Pinhal" },
    { value: "Vila Congonhas", label: "Vila Congonhas" },
    { value: "Vila Constança", label: "Vila Constança" },
    { value: "Vila Continental", label: "Vila Continental" },
    { value: "Vila Corberi", label: "Vila Corberi" },
    { value: "Vila Cordeiro", label: "Vila Cordeiro" },
    { value: "Vila Cosmopolita", label: "Vila Cosmopolita" },
    { value: "Vila Costa Melo", label: "Vila Costa Melo" },
    { value: "Vila Cristália", label: "Vila Cristália" },
    { value: "Vila Cruz das Almas", label: "Vila Cruz das Almas" },
    { value: "Vila Cruzeiro", label: "Vila Cruzeiro" },
    { value: "Vila Cunha Bueno", label: "Vila Cunha Bueno" },
    { value: "Vila Curuçá", label: "Vila Curuçá" },
    { value: "Vila da Paz", label: "Vila da Paz" },
    { value: "Vila da Saúde", label: "Vila da Saúde" },
    { value: "Vila Dalila", label: "Vila Dalila" },
    { value: "Vila Dalva", label: "Vila Dalva" },
    { value: "Vila Damaceno", label: "Vila Damaceno" },
    { value: "Vila Danúbio Azul", label: "Vila Danúbio Azul" },
    { value: "Vila Darli", label: "Vila Darli" },
    { value: "Vila das Belezas", label: "Vila das Belezas" },
    { value: "Vila das Mercês", label: "Vila das Mercês" },
    { value: "Vila Deodoro", label: "Vila Deodoro" },
    { value: "Vila Dinorah", label: "Vila Dinorah" },
    { value: "Vila Dionisia", label: "Vila Dionisia" },
    { value: "Vila Diva", label: "Vila Diva" },
    { value: "Vila Divina Pastora", label: "Vila Divina Pastora" },
    { value: "Vila do Bosque", label: "Vila do Bosque" },
    { value: "Vila do Castelo", label: "Vila do Castelo" },
    { value: "Vila do Encontro", label: "Vila do Encontro" },
    { value: "Vila do Sol", label: "Vila do Sol" },
    { value: "Vila Dom Pedro I", label: "Vila Dom Pedro I" },
    { value: "Vila Dom Pedro II", label: "Vila Dom Pedro II" },
    { value: "Vila Domitila", label: "Vila Domitila" },
    { value: "Vila Dona Augusta", label: "Vila Dona Augusta" },
    { value: "Vila Dona Meta", label: "Vila Dona Meta" },
    { value: "Vila Dona Sara", label: "Vila Dona Sara" },
    { value: "Vila Dorna", label: "Vila Dorna" },
    { value: "Vila dos Andradas", label: "Vila dos Andradas" },
    { value: "Vila dos Andrades", label: "Vila dos Andrades" },
    { value: "Vila dos Minérios", label: "Vila dos Minérios" },
    { value: "Vila dos Palmares", label: "Vila dos Palmares" },
    { value: "Vila dos Remédios", label: "Vila dos Remédios" },
    { value: "Vila Doutor Eiras", label: "Vila Doutor Eiras" },
    { value: "Vila Duarte", label: "Vila Duarte" },
    { value: "Vila Ede", label: "Vila Ede" },
    { value: "Vila Elias Nigri", label: "Vila Elias Nigri" },
    { value: "Vila Elida", label: "Vila Elida" },
    { value: "Vila Elvira", label: "Vila Elvira" },
    { value: "Vila Elze", label: "Vila Elze" },
    { value: "Vila Ema", label: "Vila Ema" },
    { value: "Vila Emir", label: "Vila Emir" },
    { value: "Vila Erna", label: "Vila Erna" },
    { value: "Vila Ernesto", label: "Vila Ernesto" },
    { value: "Vila Escolar", label: "Vila Escolar" },
    { value: "Vila Espanhola", label: "Vila Espanhola" },
    { value: "Vila Esperança", label: "Vila Esperança" },
    { value: "Vila Ester", label: "Vila Ester" },
    { value: "Vila Euthalia", label: "Vila Euthalia" },
    { value: "Vila Fachini", label: "Vila Fachini" },
    { value: "Vila Fanton", label: "Vila Fanton" },
    { value: "Vila Fátima", label: "Vila Fátima" },
    { value: "Vila Fazzeoni", label: "Vila Fazzeoni" },
    { value: "Vila Feliz", label: "Vila Feliz" },
    { value: "Vila Fernandes", label: "Vila Fernandes" },
    { value: "Vila Fiat Lux", label: "Vila Fiat Lux" },
    { value: "Vila Fidalgo", label: "Vila Fidalgo" },
    { value: "Vila Fidélis Ribeiro", label: "Vila Fidélis Ribeiro" },
    { value: "Vila Firmiano Pinto", label: "Vila Firmiano Pinto" },
    { value: "Vila Formosa", label: "Vila Formosa" },
    { value: "Vila Franca", label: "Vila Franca" },
    { value: "Vila Franci", label: "Vila Franci" },
    { value: "Vila Francos", label: "Vila Francos" },
    { value: "Vila Friburgo", label: "Vila Friburgo" },
    { value: "Vila Frugoli", label: "Vila Frugoli" },
    { value: "Vila Fukuya", label: "Vila Fukuya" },
    { value: "Vila Gabriel", label: "Vila Gabriel" },
    { value: "Vila Gea", label: "Vila Gea" },
    { value: "Vila Genioli", label: "Vila Genioli" },
    { value: "Vila Germinal", label: "Vila Germinal" },
    { value: "Vila Gertrudes", label: "Vila Gertrudes" },
    { value: "Vila Gil", label: "Vila Gil" },
    { value: "Vila Gilda", label: "Vila Gilda" },
    { value: "Vila Giordano", label: "Vila Giordano" },
    { value: "Vila Gomes", label: "Vila Gomes" },
    { value: "Vila Gomes Cardim", label: "Vila Gomes Cardim" },
    { value: "Vila Gouveia", label: "Vila Gouveia" },
    { value: "Vila Graciosa", label: "Vila Graciosa" },
    { value: "Vila Granada", label: "Vila Granada" },
    { value: "Vila Graziela", label: "Vila Graziela" },
    { value: "Vila Guaca", label: "Vila Guaca" },
    { value: "Vila Guacuri", label: "Vila Guacuri" },
    { value: "Vila Guaraciaba", label: "Vila Guaraciaba" },
    { value: "Vila Guarani", label: "Vila Guarani" },
    { value: "Vila Guedes", label: "Vila Guedes" },
    { value: "Vila Guilherme", label: "Vila Guilherme" },
    { value: "Vila Guilhermina", label: "Vila Guilhermina" },
    { value: "Vila Guiomar", label: "Vila Guiomar" },
    { value: "Vila Gumercindo", label: "Vila Gumercindo" },
    { value: "Vila Gustavo", label: "Vila Gustavo" },
    { value: "Vila Hamburguesa", label: "Vila Hamburguesa" },
    { value: "Vila Hebe", label: "Vila Hebe" },
    { value: "Vila Helena", label: "Vila Helena" },
    { value: "Vila Heliopolis", label: "Vila Heliopolis" },
    { value: "Vila Hermínia", label: "Vila Hermínia" },
    { value: "Vila Homero", label: "Vila Homero" },
    { value: "Vila Hortência", label: "Vila Hortência" },
    { value: "Vila Icaraí", label: "Vila Icaraí" },
    { value: "Vila Ida", label: "Vila Ida" },
    { value: "Vila Iguaçu", label: "Vila Iguaçu" },
    { value: "Vila Império", label: "Vila Império" },
    { value: "Vila Inácio", label: "Vila Inácio" },
    { value: "Vila Inah", label: "Vila Inah" },
    { value: "Vila Independência", label: "Vila Independência" },
    { value: "Vila Independente", label: "Vila Independente" },
    { value: "Vila Indiana", label: "Vila Indiana" },
    { value: "Vila Industrial", label: "Vila Industrial" },
    { value: "Vila Inglesa", label: "Vila Inglesa" },
    { value: "Vila Invernada", label: "Vila Invernada" },
    { value: "Vila Iolanda", label: "Vila Iolanda" },
    { value: "Vila Iolanda II", label: "Vila Iolanda II" },
    { value: "Vila Iorio", label: "Vila Iorio" },
    { value: "Vila Ipojuca", label: "Vila Ipojuca" },
    { value: "Vila Irmãos Arnoni", label: "Vila Irmãos Arnoni" },
    { value: "Vila Isa", label: "Vila Isa" },
    { value: "Vila Isabel", label: "Vila Isabel" },
    { value: "Vila Isolina Mazzei", label: "Vila Isolina Mazzei" },
    { value: "Vila Itaberaba", label: "Vila Itaberaba" },
    { value: "Vila Itaim", label: "Vila Itaim" },
    { value: "Vila Ivg", label: "Vila Ivg" },
    { value: "Vila Ivone", label: "Vila Ivone" },
    { value: "Vila Jacuí", label: "Vila Jacuí" },
    { value: "Vila Jaguara", label: "Vila Jaguara" },
    { value: "Vila Jaguari", label: "Vila Jaguari" },
    { value: "Vila Jaraguá", label: "Vila Jaraguá" },
    { value: "Vila Joaniza", label: "Vila Joaniza" },
    { value: "Vila João Batista", label: "Vila João Batista" },
    { value: "Vila Júlio César", label: "Vila Júlio César" },
    { value: "Vila Jurema", label: "Vila Jurema" },
    { value: "Vila Jussara", label: "Vila Jussara" },
    { value: "Vila Lageado", label: "Vila Lageado" },
    { value: "Vila Lais", label: "Vila Lais" },
    { value: "Vila Lar Nacional", label: "Vila Lar Nacional" },
    { value: "Vila Leme", label: "Vila Leme" },
    { value: "Vila Leonor", label: "Vila Leonor" },
    { value: "Vila Leopoldina", label: "Vila Leopoldina" },
    { value: "Vila Libanesa", label: "Vila Libanesa" },
    { value: "Vila Lisboa", label: "Vila Lisboa" },
    { value: "Vila Liviero", label: "Vila Liviero" },
    { value: "Vila Londrina", label: "Vila Londrina" },
    { value: "Vila Lourdes", label: "Vila Lourdes" },
    { value: "Vila Lúcia", label: "Vila Lúcia" },
    { value: "Vila Lúcia Elvira", label: "Vila Lúcia Elvira" },
    { value: "Vila Luzimar", label: "Vila Luzimar" },
    { value: "Vila Macedópolis", label: "Vila Macedópolis" },
    { value: "Vila Madalena", label: "Vila Madalena" },
    { value: "Vila Mafra", label: "Vila Mafra" },
    { value: "Vila Malvina", label: "Vila Malvina" },
    { value: "Vila Mangalot", label: "Vila Mangalot" },
    { value: "Vila Mara", label: "Vila Mara" },
    { value: "Vila Maracanã", label: "Vila Maracanã" },
    { value: "Vila Marari", label: "Vila Marari" },
    { value: "Vila Marcelo", label: "Vila Marcelo" },
    { value: "Vila Margareth", label: "Vila Margareth" },
    { value: "Vila Margarida", label: "Vila Margarida" },
    { value: "Vila Maria", label: "Vila Maria" },
    { value: "Vila Maria Alta", label: "Vila Maria Alta" },
    { value: "Vila Maria Baixa", label: "Vila Maria Baixa" },
    { value: "Vila Maria Eugênia", label: "Vila Maria Eugênia" },
    { value: "Vila Maria Luisa", label: "Vila Maria Luisa" },
    { value: "Vila Maria Trindade", label: "Vila Maria Trindade" },
    { value: "Vila Maria Zélia", label: "Vila Maria Zélia" },
    { value: "Vila Mariana", label: "Vila Mariana" },
    { value: "Vila Marieta", label: "Vila Marieta" },
    { value: "Vila Marilena", label: "Vila Marilena" },
    { value: "Vila Marina", label: "Vila Marina" },
    { value: "Vila Mariza Mazzei", label: "Vila Mariza Mazzei" },
    { value: "Vila Marte", label: "Vila Marte" },
    { value: "Vila Mascote", label: "Vila Mascote" },
    { value: "Vila Matias", label: "Vila Matias" },
    { value: "Vila Matilde", label: "Vila Matilde" },
    { value: "Vila Mazzei", label: "Vila Mazzei" },
    { value: "Vila Medeiros", label: "Vila Medeiros" },
    { value: "Vila Mendes", label: "Vila Mendes" },
    { value: "Vila Mercedes", label: "Vila Mercedes" },
    { value: "Vila Mesquita", label: "Vila Mesquita" },
    { value: "Vila Miami", label: "Vila Miami" },
    { value: "Vila Michelina", label: "Vila Michelina" },
    { value: "Vila Minerva", label: "Vila Minerva" },
    { value: "Vila Mira", label: "Vila Mira" },
    { value: "Vila Mirante", label: "Vila Mirante" },
    { value: "Vila Miriam", label: "Vila Miriam" },
    { value: "Vila Missionária", label: "Vila Missionária" },
    { value: "Vila Moinho Velho", label: "Vila Moinho Velho" },
    { value: "Vila Monte Alegre", label: "Vila Monte Alegre" },
    { value: "Vila Monte Santo", label: "Vila Monte Santo" },
    { value: "Vila Monumento", label: "Vila Monumento" },
    { value: "Vila Moraes", label: "Vila Moraes" },
    { value: "Vila Moreira", label: "Vila Moreira" },
    { value: "Vila Morgadouro", label: "Vila Morgadouro" },
    { value: "Vila Morse", label: "Vila Morse" },
    { value: "Vila Morumbi", label: "Vila Morumbi" },
    { value: "Vila Nair", label: "Vila Nair" },
    { value: "Vila Nancy", label: "Vila Nancy" },
    { value: "Vila Narciso", label: "Vila Narciso" },
    { value: "Vila Nascente", label: "Vila Nascente" },
    { value: "Vila Natal", label: "Vila Natal" },
    { value: "Vila Natália", label: "Vila Natália" },
    { value: "Vila Neila", label: "Vila Neila" },
    { value: "Vila Nelson", label: "Vila Nelson" },
    { value: "Vila Nhocune", label: "Vila Nhocune" },
    { value: "Vila Nilo", label: "Vila Nilo" },
    { value: "Vila Nilva", label: "Vila Nilva" },
    { value: "Vila Nina", label: "Vila Nina" },
    { value: "Vila Nivi", label: "Vila Nivi" },
    { value: "Vila Noca", label: "Vila Noca" },
    { value: "Vila Nogueira", label: "Vila Nogueira" },
    { value: "Vila Norma", label: "Vila Norma" },
    { value: "Vila Nossa Senhora Aparecida", label: "Vila Nossa Senhora Aparecida" },
    { value: "Vila Nossa Senhora do Retiro", label: "Vila Nossa Senhora do Retiro" },
    { value: "Vila Nova", label: "Vila Nova" },
    { value: "Vila Nova Alba", label: "Vila Nova Alba" },
    { value: "Vila Nova Cachoeirinha", label: "Vila Nova Cachoeirinha" },
    { value: "Vila Nova Caledonia", label: "Vila Nova Caledonia" },
    { value: "Vila Nova Carolina", label: "Vila Nova Carolina" },
    { value: "Vila Nova Conceição", label: "Vila Nova Conceição" },
    { value: "Vila Nova Curuçá", label: "Vila Nova Curuçá" },
    { value: "Vila Nova das Belezas", label: "Vila Nova das Belezas" },
    { value: "Vila Nova Galvão", label: "Vila Nova Galvão" },
    { value: "Vila Nova Jaguare", label: "Vila Nova Jaguare" },
    { value: "Vila Nova Manchester", label: "Vila Nova Manchester" },
    { value: "Vila Nova Mazzei", label: "Vila Nova Mazzei" },
    { value: "Vila Nova Parada", label: "Vila Nova Parada" },
    { value: "Vila Nova Pauliceia", label: "Vila Nova Pauliceia" },
    { value: "Vila Nova Pirajussara", label: "Vila Nova Pirajussara" },
    { value: "Vila Nova Savoia", label: "Vila Nova Savoia" },
    { value: "Vila Nova Teresa", label: "Vila Nova Teresa" },
    { value: "Vila Nova Utinga", label: "Vila Nova Utinga" },
    { value: "Vila Nova York", label: "Vila Nova York" },
    { value: "Vila Odete", label: "Vila Odete" },
    { value: "Vila Olga", label: "Vila Olga" },
    { value: "Vila Olímpia", label: "Vila Olímpia" },
    { value: "Vila Olinda", label: "Vila Olinda" },
    { value: "Vila Oratório", label: "Vila Oratório" },
    { value: "Vila Paiva", label: "Vila Paiva" },
    { value: "Vila Palmeiras", label: "Vila Palmeiras" },
    { value: "Vila Paranaguá", label: "Vila Paranaguá" },
    { value: "Vila Parque Jabaquara", label: "Vila Parque Jabaquara" },
    { value: "Vila Patrimonial", label: "Vila Patrimonial" },
    { value: "Vila Pauliceia", label: "Vila Pauliceia" },
    { value: "Vila Paulista", label: "Vila Paulista" },
    { value: "Vila Paulista I", label: "Vila Paulista I" },
    { value: "Vila Paulista II", label: "Vila Paulista II" },
    { value: "Vila Paulistana", label: "Vila Paulistana" },
    { value: "Vila Paulistania", label: "Vila Paulistania" },
    { value: "Vila Paulo Silas", label: "Vila Paulo Silas" },
    { value: "Vila Pedra Branca", label: "Vila Pedra Branca" },
    { value: "Vila Pedroso", label: "Vila Pedroso" },
    { value: "Vila Penteado", label: "Vila Penteado" },
    { value: "Vila Pereira Barreto", label: "Vila Pereira Barreto" },
    { value: "Vila Pereira Cerca", label: "Vila Pereira Cerca" },
    { value: "Vila Perus", label: "Vila Perus" },
    { value: "Vila Piauí", label: "Vila Piauí" },
    { value: "Vila Picinin", label: "Vila Picinin" },
    { value: "Vila Pierina", label: "Vila Pierina" },
    { value: "Vila Piracicaba", label: "Vila Piracicaba" },
    { value: "Vila Pirajussara", label: "Vila Pirajussara" },
    { value: "Vila Pirituba", label: "Vila Pirituba" },
    { value: "Vila Pita", label: "Vila Pita" },
    { value: "Vila Plana", label: "Vila Plana" },
    { value: "Vila Polopoli", label: "Vila Polopoli" },
    { value: "Vila Pompeia", label: "Vila Pompeia" },
    { value: "Vila Ponte Rasa", label: "Vila Ponte Rasa" },
    { value: "Vila Popular", label: "Vila Popular" },
    { value: "Vila Portugal", label: "Vila Portugal" },
    { value: "Vila Portuguesa", label: "Vila Portuguesa" },
    { value: "Vila Prado", label: "Vila Prado" },
    { value: "Vila Praia", label: "Vila Praia" },
    { value: "Vila Prelado", label: "Vila Prelado" },
    { value: "Vila Primavera", label: "Vila Primavera" },
    { value: "Vila Princesa Isabel", label: "Vila Princesa Isabel" },
    { value: "Vila Progredior", label: "Vila Progredior" },
    { value: "Vila Progresso", label: "Vila Progresso" },
    { value: "Vila Prudente", label: "Vila Prudente" },
    { value: "Vila Quintana", label: "Vila Quintana" },
    { value: "Vila Ramos", label: "Vila Ramos" },
    { value: "Vila Raquel", label: "Vila Raquel" },
    { value: "Vila Regente Feijó", label: "Vila Regente Feijó" },
    { value: "Vila Regina", label: "Vila Regina" },
    { value: "Vila Reis", label: "Vila Reis" },
    { value: "Vila Remo", label: "Vila Remo" },
    { value: "Vila Renato", label: "Vila Renato" },
    { value: "Vila Represa", label: "Vila Represa" },
    { value: "Vila Ribeiro de Barros", label: "Vila Ribeiro de Barros" },
    { value: "Vila Rica", label: "Vila Rica" },
    { value: "Vila Rio Branco", label: "Vila Rio Branco" },
    { value: "Vila Robertina", label: "Vila Robertina" },
    { value: "Vila Romana", label: "Vila Romana" },
    { value: "Vila Romano", label: "Vila Romano" },
    { value: "Vila Romero", label: "Vila Romero" },
    { value: "Vila Roque", label: "Vila Roque" },
    { value: "Vila Rosa", label: "Vila Rosa" },
    { value: "Vila Rosa Molla", label: "Vila Rosa Molla" },
    { value: "Vila Rosaria", label: "Vila Rosaria" },
    { value: "Vila Roschel", label: "Vila Roschel" },
    { value: "Vila Roseira II", label: "Vila Roseira II" },
    { value: "Vila Rosina", label: "Vila Rosina" },
    { value: "Vila Rubi", label: "Vila Rubi" },
    { value: "Vila Ruela", label: "Vila Ruela" },
    { value: "Vila Rui Barbosa", label: "Vila Rui Barbosa" },
    { value: "Vila Sabrina", label: "Vila Sabrina" },
    { value: "Vila Salete", label: "Vila Salete" },
    { value: "Vila Sampaio", label: "Vila Sampaio" },
    { value: "Vila Santa Catarina", label: "Vila Santa Catarina" },
    { value: "Vila Santa Clara", label: "Vila Santa Clara" },
    { value: "Vila Santa Cruz", label: "Vila Santa Cruz" },
    { value: "Vila Santa Delfina", label: "Vila Santa Delfina" },
    { value: "Vila Santa Edwiges", label: "Vila Santa Edwiges" },
    { value: "Vila Santa Eulalia", label: "Vila Santa Eulalia" },
    { value: "Vila Santa Ines", label: "Vila Santa Ines" },
    { value: "Vila Santa Isabel", label: "Vila Santa Isabel" },
    { value: "Vila Santa Lúcia", label: "Vila Santa Lúcia" },
    { value: "Vila Santa Maria", label: "Vila Santa Maria" },
    { value: "Vila Santa Teresa", label: "Vila Santa Teresa" },
    { value: "Vila Santa Teresinha", label: "Vila Santa Teresinha" },
    { value: "Vila Santa Terezinha", label: "Vila Santa Terezinha" },
    { value: "Vila Santa Virgínia", label: "Vila Santa Virgínia" },
    { value: "Vila Santana", label: "Vila Santana" },
    { value: "Vila Santista", label: "Vila Santista" },
    { value: "Vila Santo Antônio", label: "Vila Santo Antônio" },
    { value: "Vila Santo Estefano", label: "Vila Santo Estefano" },
    { value: "Vila Santo Estêvão", label: "Vila Santo Estêvão" },
    { value: "Vila Santo Henrique", label: "Vila Santo Henrique" },
    { value: "Vila Santos", label: "Vila Santos" },
    { value: "Vila São Domingos", label: "Vila São Domingos" },
    { value: "Vila São Francisco", label: "Vila São Francisco" },
    { value: "Vila São Geraldo", label: "Vila São Geraldo" },
    { value: "Vila São José", label: "Vila São José" },
    { value: "Vila São Judas", label: "Vila São Judas" },
    { value: "Vila São Luís", label: "Vila São Luís" },
    { value: "Vila São Nicolau", label: "Vila São Nicolau" },
    { value: "Vila São Paulo", label: "Vila São Paulo" },
    { value: "Vila São Pedro", label: "Vila São Pedro" },
    { value: "Vila São Silvestre", label: "Vila São Silvestre" },
    { value: "Vila São Vicente", label: "Vila São Vicente" },
    { value: "Vila Sapopemba", label: "Vila Sapopemba" },
    { value: "Vila Seabra", label: "Vila Seabra" },
    { value: "Vila Sérgio", label: "Vila Sérgio" },
    { value: "Vila Serralheiro", label: "Vila Serralheiro" },
    { value: "Vila Silva Teles", label: "Vila Silva Teles" },
    { value: "Vila Sílvia", label: "Vila Sílvia" },
    { value: "Vila Simone", label: "Vila Simone" },
    { value: "Vila Sinhá", label: "Vila Sinhá" },
    { value: "Vila Siqueira", label: "Vila Siqueira" },
    { value: "Vila Sirene", label: "Vila Sirene" },
    { value: "Vila Síria", label: "Vila Síria" },
    { value: "Vila Socorro", label: "Vila Socorro" },
    { value: "Vila Sofia", label: "Vila Sofia" },
    { value: "Vila Solange", label: "Vila Solange" },
    { value: "Vila Sônia", label: "Vila Sônia" },
    { value: "Vila Souza", label: "Vila Souza" },
    { value: "Vila Stela", label: "Vila Stela" },
    { value: "Vila Suíça", label: "Vila Suíça" },
    { value: "Vila Sulina", label: "Vila Sulina" },
    { value: "Vila Suzana", label: "Vila Suzana" },
    { value: "Vila Talarico", label: "Vila Talarico" },
    { value: "Vila Taquari", label: "Vila Taquari" },
    { value: "Vila Teresinha", label: "Vila Teresinha" },
    { value: "Vila Tiradentes", label: "Vila Tiradentes" },
    { value: "Vila Tolstoi", label: "Vila Tolstoi" },
    { value: "Vila Tramontano", label: "Vila Tramontano" },
    { value: "Vila Uberabinha", label: "Vila Uberabinha" },
    { value: "Vila União", label: "Vila União" },
    { value: "Vila Universitária", label: "Vila Universitária" },
    { value: "Vila Valdemar", label: "Vila Valdemar" },
    { value: "Vila Vasconcelos", label: "Vila Vasconcelos" },
    { value: "Vila Vera", label: "Vila Vera" },
    { value: "Vila Verde", label: "Vila Verde" },
    { value: "Vila Vermelha", label: "Vila Vermelha" },
    { value: "Vila Vessoni", label: "Vila Vessoni" },
    { value: "Vila Virgínia", label: "Vila Virgínia" },
    { value: "Vila Vitório Mazzei", label: "Vila Vitório Mazzei" },
    { value: "Vila Yara", label: "Vila Yara" },
    { value: "Vila Zat", label: "Vila Zat" },
    { value: "Vila Zefira", label: "Vila Zefira" },
    { value: "Vila Zelina", label: "Vila Zelina" },
    { value: "Vila Zilda", label: "Vila Zilda" },
    { value: "Vila Zulmira", label: "Vila Zulmira" }
];

export default bairrosSP;
