import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './Hero.module.scss';
import CountSignatures from "./CountSignatures";

const Hero = ({left, logo, right, channel, backgroundClass}) => {
    const leftBox = left ? <p className={[styles.title, styles.textRight, styles.colorDark].join(' ')}>{left}</p> : "";
    const rightBox = right ? <p className={[styles.title, styles.textLeft].join(' ')}>{right}</p> : "";

    const counter = channel ? <CountSignatures channel={channel} /> : "";

    const background = backgroundClass ?  backgroundClass : "default-background";

    return (
        <div className={[styles.container + ' ' + background]} id="#">
            <div className={styles.wrap}>
                <div className={styles.content}>
                    { leftBox }
                    <Img className={styles.logo} fluid={logo} imgStyle={{ objectFit: 'contain' }}/>
                    { rightBox }
                </div>
                { counter }
            </div>
        </div>
    );
}

Hero.propTypes = {
    channel: PropTypes.string,
    left: PropTypes.string,
    logo: PropTypes.object,
    right: PropTypes.string,
    backgroundClass: PropTypes.string,
};

export default Hero;
